/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";


/*
 * App CSS
 * ----------------------------------------------------------------------------
 * Imports a file that can contain Sass/CSS that should be used throughout
 * the entire app.
 */



// placed here instead of tutorial.scss due to slide els not getting ng scoping attribute
.swiper-slide {
  display: block;
}

/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

// anima style guide
:root {
  --aqua-squeeze-old: #e7f6f1;
  --black: #000000;
  --black-russian: #000021;
  --cardinal: #ce2029;
  --carnation: #ff5861;
  --cherokee: #ffd794;
  --concrete: #f2f2f2;
  --cosmos: #ffdadc;
  --desert-storm: #f7f8f8;
  --edgewater: #b8e2d4;
  --green-haze: #009460;
  --mercury: #e6e6e6;
  --papaya-whip: #ffeed1;
  --pink-swan: #b9b9b9;
  --scarpa-flow: #50555c;
  --shady-lady: #aca3a5;
  --spicy-pink: #7b6f72;
  --sundown: #ffb6b9;
  --tree-poppy: #f2a31d;
  --white: #ffffff;

  --font-size-l: 15px;
  --font-size-m: 14px;
  --font-size-s: 12px;
  --font-size-xl: 16px;
  --font-size-xs: 10px;
  --font-size-xxl: 18px;
  --font-size-xxxl: 24px;

  --font-family-circular_std-medium: "Circular Std-Medium", Helvetica;
  --font-family-inter: "Inter", Helvetica;
  --font-family-outfit: "Outfit", Helvetica;
  --font-family-poppins: "Poppins", Helvetica;
  --font-family-sf_pro_display-regular: "SF Pro Display-Regular", Helvetica;
  --font-family-sf_pro_text-semibold: "SF Pro Text-SemiBold", Helvetica;
  // common standard colors
  --button-green: #1dc78b;
  // DriveCarO = greenish #1dc78b
  // --green: #0bb076;
  --mountain-meadow: #1dc78b;
  --aqua-squeeze: #1dc78b1a;
  // Bharat Theme- redish color #7b1c1a
  --green: #7b1c1a;
  --mountain-meadow: #7b1c1a;
  --aqua-squeeze: #7b1c1a1a;
  // Bharat Theme END
  // // Tirumala Theme Begin
  // --mountain-meadow: #c12626;
  // --aqua-squeeze: #c126261a;
  // // Tirumala Theme End

  // Test yellow begin
  // --green: yellow;
  // --mountain-meadow: yellow;
  // Test yellow end
}

.color-svg {
  color: var(--mountain-meadow);
}

.img-23 {
  width: 23px;
  height: 23px;
}

.poppins-normal-black-14px {
  color: var(--black);
  font-family: var(--font-family-poppins);
  font-size: var(--font-size-m);
  font-style: normal;
  font-weight: 400;
}

.poppins-normal-black-12px {
  color: var(--black);
  font-family: var(--font-family-poppins);
  font-size: var(--font-size-s);
  font-style: normal;
  font-weight: 400;
}

.poppins-normal-red-12px {
  color: #e36f33;
  font-family: var(--font-family-poppins);
  font-size: var(--font-size-s);
  font-style: normal;
  font-weight: bold;
}

.poppins-normal-black-10px {
  color: var(--black);
  font-family: var(--font-family-poppins);
  font-size: var(--font-size-xs);
  font-style: normal;
  font-weight: 400;
  text-align: left;
}

.poppins-bold-black-12px {
  color: var(--black);
  font-family: var(--font-family-poppins);
  font-size: var(--font-size-s);
  font-style: normal;
  font-weight: 700;
}

.poppins-medium-black-14px {
  color: var(--black);
  font-family: var(--font-family-poppins);
  font-size: var(--font-size-m);
  font-style: normal;
  font-weight: 500;
}

.poppins-semi-bold-black-16px {
  color: var(--black);
  font-family: var(--font-family-poppins);
  font-size: var(--font-size-xl);
  font-style: normal;
  font-weight: 600;
}

.outfit-normal-pink-swan-10px {
  color: var(--pink-swan);
  font-family: var(--font-family-outfit);
  font-size: var(--font-size-xs);
  font-style: normal;
  font-weight: 400;
}

.poppins-bold-mountain-meadow-14px {
  color: var(--button-green);
  font-family: var(--font-family-poppins);
  font-size: var(--font-size-m);
  font-style: normal;
  font-weight: 700;
}

.poppins-semi-bold-mountain-meadow-14px {
  color: var(--mountain-meadow);
  font-family: var(--font-family-poppins);
  font-size: var(--font-size-m);
  font-style: normal;
  font-weight: 600;
}

.poppins-semi-bold-mountain-meadow-12px {
  @extend .poppins-semi-bold-mountain-meadow-14px;
  font-size: var(--font-size-s);
  font-weight: 400;
}

.poppins-normal-white-20px {
  color: var(--white);
  font-family: var(--font-family-poppins);
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
}

.poppins-medium-black-16px {
  color: var(--black);
  font-family: var(--font-family-poppins);
  font-size: var(--font-size-xl);
  font-style: normal;
  font-weight: 500;
}

.poppins-medium-black-18px {
  color: var(--black);
  font-family: var(--font-family-poppins);
  font-size: var(--font-size-xxl);
  font-style: normal;
  font-weight: 500;
}

.poppins-normal-black-15px {
  color: var(--black);
  font-family: var(--font-family-poppins);
  font-size: var(--font-size-l);
  font-style: normal;
  font-weight: 400;
}

.poppins-normal-mountain-meadow-10px {
  color: var(--mountain-meadow);
  font-family: var(--font-family-poppins);
  font-size: var(--font-size-xs);
  font-style: normal;
  font-weight: 400;
}

.poppins-medium-black-10px {
  color: var(--black);
  font-family: var(--font-family-poppins);
  font-size: var(--font-size-xs);
  font-style: normal;
  font-weight: 500;
}

.poppins-medium-white-10px {
  @extend .poppins-medium-black-10px;
  color: var(--white);
}

.circularstd-medium-black-russian-15px {
  color: var(--black-russian);
  font-family: var(--font-family-circular_std-medium);
  font-size: var(--font-size-l);
  font-style: normal;
  font-weight: 500;
}

.poppins-medium-white-10-9px {
  color: var(--white);
  font-family: var(--font-family-poppins);
  font-size: 10.9px;
  font-style: normal;
  font-weight: 500;
}

.poppins-medium-white-12-7px {
  color: var(--white);
  font-family: var(--font-family-poppins);
  font-size: 12.7px;
  font-style: normal;
  font-weight: 500;
}

.inter-normal-spicy-pink-12px {
  color: var(--spicy-pink);
  font-family: var(--font-family-inter);
  font-size: var(--font-size-s);
  font-style: normal;
  font-weight: 400;
}

.inter-medium-spicy-pink-14px {
  color: var(--spicy-pink);
  font-family: var(--font-family-inter);
  font-size: var(--font-size-m);
  font-style: normal;
  font-weight: 500;
}

.poppins-medium-black-12px {
  color: var(--black);
  font-family: var(--font-family-poppins);
  font-size: var(--font-size-s);
  font-style: normal;
  font-weight: 500;
}

.circularstd-medium-white-15px {
  color: var(--white);
  font-family: var(--font-family-circular_std-medium);
  font-size: var(--font-size-l);
  font-style: normal;
  font-weight: 500;
}

.poppins-normal-green-haze-15px {
  color: var(--green-haze);
  font-family: var(--font-family-poppins);
  font-size: var(--font-size-l);
  font-style: normal;
  font-weight: 400;
}

.sfprodisplay-regular-normal-black-25px {
  color: var(--black);
  font-family: var(--font-family-sf_pro_display-regular);
  font-size: 25px;
  font-style: normal;
  font-weight: 400;
}

.poppins-medium-mountain-meadow-14px {
  color: var(--button-green);
  font-family: var(--font-family-poppins);
  font-size: var(--font-size-m);
  font-style: normal;
  font-weight: 500;
}

.poppins-normal-green-10px {
  color: var(--button-green);
  font-family: var(--font-family-poppins);
  font-size: var(--font-size-xs);
  font-style: normal;
  font-weight: 400;
}

.poppins-medium-green-12px {
  color: var(--button-green);
  font-family: var(--font-family-poppins);
  font-size: var(--font-size-s);
  font-style: normal;
  font-weight: 500;
}

.poppins-medium-white-12px {
  color: var(--white);
  font-family: var(--font-family-poppins);
  font-size: var(--font-size-s);
  font-style: normal;
  font-weight: 500;
}

.poppins-bold-tree-poppy-14px {
  color: var(--tree-poppy);
  font-family: var(--font-family-poppins);
  font-size: var(--font-size-m);
  font-style: normal;
  font-weight: 700;
}

.poppins-bold-cardinal-14px {
  color: var(--cardinal);
  font-family: var(--font-family-poppins);
  font-size: var(--font-size-m);
  font-style: normal;
  font-weight: 700;
}

.sfprotext-semi-bold-black-10px {
  color: var(--black);
  font-family: var(--font-family-sf_pro_text-semibold);
  font-size: var(--font-size-xs);
  font-style: normal;
  font-weight: 600;
}

.outfit-normal-black-10px {
  color: var(--black);
  font-family: var(--font-family-outfit);
  font-size: var(--font-size-xs);
  font-style: normal;
  font-weight: 400;
}

.poppins-normal-green-14px {
  color: var(--mountain-meadow);
  font-family: var(--font-family-poppins);
  font-size: var(--font-size-m);
  font-style: normal;
  font-weight: 400;
}

.poppins-bold-white-24px {
  color: var(--white);
  font-family: var(--font-family-poppins);
  font-size: var(--font-size-xxxl);
  font-style: normal;
  font-weight: 700;
}

.poppins-medium-white-15px {
  color: var(--white);
  font-family: var(--font-family-poppins);
  font-size: var(--font-size-l);
  font-style: normal;
  font-weight: 500;
}

.poppins-normal-mountain-meadow-14px {
  color: var(--mountain-meadow);
  font-family: var(--font-family-poppins);
  font-size: var(--font-size-m);
  font-style: normal;
  font-weight: 400;
}

.poppins-bold-black-15px {
  color: var(--black);
  font-family: var(--font-family-poppins);
  font-size: var(--font-size-l);
  font-style: normal;
  font-weight: 700;
}

.poppins-semi-bold-black-15px {
  color: var(--black);
  font-family: var(--font-family-poppins);
  font-size: var(--font-size-l);
  font-style: normal;
  font-weight: 600;
}

.poppins-normal-black-8px {
  color: var(--black);
  font-family: var(--font-family-poppins);
  font-size: 8px;
  font-style: normal;
  font-weight: 400;
}

.poppins-bold-black-24px {
  color: var(--black);
  font-family: var(--font-family-poppins);
  font-size: var(--font-size-xxxl);
  font-style: normal;
  font-weight: 700;
}

.poppins-bold-black-14px {
  color: var(--black);
  font-family: var(--font-family-poppins);
  font-size: var(--font-size-m);
  font-style: normal;
  font-weight: 700;
}

.poppins-normal-mountain-meadow-18px {
  color: var(--mountain-meadow);
  font-family: var(--font-family-poppins);
  font-size: var(--font-size-xxl);
  font-style: normal;
  font-weight: 400;
}

.poppins-normal-white-14px {
  color: var(--white);
  font-family: var(--font-family-poppins);
  font-size: var(--font-size-m);
  font-style: normal;
  font-weight: 400;
}

.poppins-semi-bold-mountain-meadow-10px {
  // color: var(--mountain-meadow);
  font-family: var(--font-family-poppins);
  font-size: var(--font-size-xs);
  font-style: normal;
  font-weight: 600;
}

.poppins-semi-bold-tree-poppy-10px {
  // color: var(--tree-poppy);
  font-family: var(--font-family-poppins);
  font-size: var(--font-size-xs);
  font-style: normal;
  font-weight: 600;
}

.poppins-semi-bold-cardinal-10px {
  // color: var(--cardinal);
  font-family: var(--font-family-poppins);
  font-size: var(--font-size-xs);
  font-style: normal;
  font-weight: 600;
}

.poppins-normal-black-14-5px {
  color: var(--black);
  font-family: var(--font-family-poppins);
  font-size: 14.5px;
  font-style: normal;
  font-weight: 400;
}

.poppins-normal-black-16px {
  color: var(--black);
  font-family: var(--font-family-poppins);
  font-size: var(--font-size-xl);
  font-style: normal;
  font-weight: 400;
}

.poppins-normal-black-12-7px {
  color: var(--black);
  font-family: var(--font-family-poppins);
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
}

.poppins-normal-green-haze-10px {
  color: var(--green-haze);
  font-family: var(--font-family-poppins);
  font-size: var(--font-size-xs);
  font-style: normal;
  font-weight: 400;
}

.poppins-normal-shady-lady-14px {
  color: var(--shady-lady);
  font-family: var(--font-family-poppins);
  font-size: var(--font-size-m);
  font-style: normal;
  font-weight: 400;
}

.inter-normal-white-12px {
  color: var(--white);
  font-family: var(--font-family-inter);
  font-size: var(--font-size-s);
  font-style: normal;
  font-weight: 400;
}

.inter-medium-white-14px {
  color: var(--white);
  font-family: var(--font-family-inter);
  font-size: var(--font-size-m);
  font-style: normal;
  font-weight: 500;
}

.poppins-normal-green-12px {
  color: var(--button-green);
  font-family: var(--font-family-poppins);
  font-size: var(--font-size-s);
  font-style: normal;
  font-weight: 400;
}

.poppins-normal-carnation-15px {
  color: var(--carnation);
  font-family: var(--font-family-poppins);
  font-size: var(--font-size-l);
  font-style: normal;
  font-weight: 400;
}

.poppins-normal-white-120px {
  color: var(--white);
  font-family: var(--font-family-poppins);
  font-size: 120px;
  font-style: normal;
  font-weight: 400;
}

.poppins-normal-white-16px {
  color: var(--white);
  font-family: var(--font-family-poppins);
  font-size: var(--font-size-xl);
  font-style: normal;
  font-weight: 400;
}

.poppins-medium-white-14-5px {
  color: var(--white);
  font-family: var(--font-family-poppins);
  font-size: 14.5px;
  font-style: normal;
  font-weight: 500;
}

.poppins-medium-white-14px {
  color: var(--white);
  font-family: var(--font-family-poppins);
  font-size: var(--font-size-m);
  font-style: normal;
  font-weight: 500;
}

.poppins-normal-white-15px {
  color: var(--white);
  font-family: var(--font-family-poppins);
  font-size: var(--font-size-l);
  font-style: normal;
  font-weight: 400;
}

.sfprodisplay-regular-normal-scarpa-flow-22px {
  color: var(--scarpa-flow);
  font-family: var(--font-family-sf_pro_display-regular);
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
}



// anima global
@import url("https://cdnjs.cloudflare.com/ajax/libs/meyer-reset/2.0/reset.min.css");
@import url("https://fonts.googleapis.com/css?family=Poppins:400,700,500,600|Outfit:400|Inter:400,500");
/* The following line is used to measure usage of this code in production. For more info see our usage billing page */
// @import url("https://px.animaapp.com/641491ab7d9e69869d2835f8.641491adb07195ba5e7e1f25.6d3hOS6.hcp.png");

@font-face {
  font-family: "Circular Std-Medium";
  font-style: normal;
  font-weight: 500;
  src: url("./assets/fonts/circular-std-medium-500.ttf") format("truetype");
}


.screen a {
  display: contents;
  text-decoration: none;
}

.container-center-horizontal {
  display: flex;
  flex-direction: row;
  justify-content: center;
  pointer-events: none;
  width: 100%;
}

.container-center-horizontal>* {
  flex-shrink: 0;
  pointer-events: auto;
}

* {
  box-sizing: border-box;
}

.status_-bar {
  align-items: flex-start;
  display: flex;
  gap: 234px;
  height: 45px;
  padding: 12px 27.0px;
  width: 390px;
}

.text {
  letter-spacing: 0;
  line-height: normal;
  margin-left: 1px;
  min-height: 19px;
  width: 29px;
}

.group-33325 {
  align-items: flex-start;
  align-self: center;
  display: flex;
  margin-top: 0.34px;
  min-width: 72px;
}

.mobile-signal {
  align-self: center;
  height: 11px;
  margin-top: 0;
  width: 18px;
}

.icon-signal {
  height: 11px;
  margin-left: 5px;
  margin-top: 0;
  width: 16px;
}

.battery {
  align-self: flex-end;
  height: 11px;
  margin-bottom: 0;
  margin-left: 6px;
  width: 26px;
}

.vuesaxboldarrow-circle-right {
  height: 18px;
  width: 18px;
}

.vuesax {
  height: 16px;
  width: 16px;
}

.status_-bar-1 {
  align-items: flex-start;
  display: flex;
  gap: 234px;
  height: 45px;
  left: 0;
  padding: 12px 27.0px;
  position: absolute;
  top: 0;
  width: 390px;
}

.group-33325-1 {
  align-items: flex-start;
  align-self: center;
  display: flex;
  justify-content: center;
  margin-top: 0.34px;
  min-width: 72px;
}

.group-33356 {
  align-items: center;
  display: flex;
  gap: 78px;
  // margin-top: 7px;
  min-width: 100%;
  width: 100%;
  justify-content: center;
}

.place-1 {
  letter-spacing: 0;
  line-height: normal;
  // margin-bottom: 5.0px;
  min-height: 15px;
  min-width: 31px;
}

.group-33355 {
  align-items: center;
  display: flex;
  gap: 4px;
  min-width: 79px;
}

.vuesaxoutlinerouting {
  height: 20px;
  width: 20px;
}

.group-33391 {
  align-items: flex-start;
  display: flex;
  /* gap: 31px; */
  height: 35px;
  margin-left: 1px;
  margin-top: 11px;
  margin-bottom: 10px;
  /* min-width: 343px; */
  width: 360px;
  padding-left: 20px;
  padding-right: 20px;
  justify-content: space-between;
}

.group-33387 {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 3px;
  min-height: 35px;
  width: 66px;
}

.group-33383 {
  align-items: flex-start;
  display: flex;
  gap: 4px;
  min-width: 66px;
}

.x8km {
  letter-spacing: 0;
  line-height: normal;
  margin-right: 1.0px;
  min-height: 15px;
  min-width: 23px;
  opacity: 0.5;
}

.group-33388 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 3px;
  min-height: 35px;
  width: 47px;
}

.group-33384 {
  align-items: flex-start;
  display: flex;
  gap: 4px;
  min-width: 47px;
}

.group-33389 {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 3px;
  min-height: 35px;
  width: 72px;
}

.group-33385 {
  align-items: flex-start;
  display: flex;
  gap: 4px;
  min-width: 72px;
}

.start-date {
  letter-spacing: 0;
  line-height: normal;
  min-height: 15px;
  min-width: 50px;
  text-align: center;
}

.group-33390 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 3px;
  min-height: 35px;
  width: 67px;
}

.group-33386 {
  align-items: flex-start;
  display: flex;
  gap: 4px;
  min-width: 67px;
}

.end-date {
  letter-spacing: 0;
  line-height: normal;
  min-height: 15px;
}

.x-details {
  letter-spacing: 0;
  line-height: normal;
  min-height: 24px;
}

.total-classes {
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  min-height: 21px;
  min-width: 93px;
  opacity: 0.5;
}

.completed-classes {
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  min-height: 21px;
  min-width: 138px;
  opacity: 0.5;
}

.frame-33397 {
  align-items: flex-start;
  display: flex;
  gap: 8px;
  height: 21px;
  // margin-top: 16px;
  min-width: 158px;
}

.remaining-classes {
  min-width: 134px;
}

.group-33393 {
  align-items: center;
  display: flex;
  flex-direction: column;
  left: 16px;
  min-height: 39px;
  position: absolute;
  top: 21px;
  width: 62px;
  z-index: 9;
}

.percent {
  letter-spacing: 0;
  line-height: normal;
  margin-right: 5.0px;
  margin-top: -1px;
  min-height: 24px;
  min-width: 33px;
}

.completed {
  letter-spacing: 0;
  line-height: normal;
  margin-right: 4.0px;
  min-height: 15px;
  min-width: 58px;
}

.group-33392 {
  align-items: flex-start;
  background-color: var(--edgewater);
  border-radius: 57.5px;
  display: flex;
  height: 85px;
  left: 0;
  min-width: 85px;
  position: absolute;
  top: 0;
}

.ellipse-15 {
  background-color: var(--button-green);
  border-radius: 57.5px;
  height: 85px;
  width: 85px;
}

.total-k-ms {
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  min-height: 21px;
  min-width: 67px;
  opacity: 0.5;
}

.driven-k-ms {
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  min-height: 21px;
  min-width: 76px;
  opacity: 0.5;
}

.frame-33397-1 {
  min-width: 134px;
}

.remaining-k-ms {
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  min-height: 21px;
  min-width: 107px;
  opacity: 0.5;
}

.group-33392-1 {
  background-color: var(--cherokee);
}

.ellipse-15-1 {
  background-color: var(--tree-poppy);
}

.total-h-rs {
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  min-height: 21px;
  min-width: 64px;
  opacity: 0.5;
}

.driven-h-rs {
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  min-height: 21px;
  min-width: 74px;
  opacity: 0.5;
}

.frame-33397-2 {
  min-width: 120px;
}

.average-h-rs {
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  min-height: 21px;
  min-width: 89px;
  opacity: 0.5;
}

.percent-1 {
  letter-spacing: 0;
  line-height: normal;
  margin-right: 4.0px;
  margin-top: -1px;
  min-height: 24px;
  min-width: 34px;
}

.group-33392-2 {
  background-color: var(--sundown);
}

.ellipse-15-2 {
  background-color: var(--cardinal);
}

.frame-container {
  align-items: flex-start;
  display: flex;
  gap: 20px;
  // height: 171px;
  margin-top: 10px;
  min-width: 100%;
  justify-content: center;
}

.frame-3339 {
  align-items: center;
  // background-color: #e7f6f1;
  background-color: var(--aqua-squeeze-old);
  border-radius: 25px;
  display: flex;
  flex-direction: column;
  padding: 15px 9px;
  width: 48%;
}

.missed {
  letter-spacing: 0;
  line-height: normal;
  margin-left: 1.0px;
  min-height: 24px;
  min-width: 57px;
  text-align: center;
}

.classes {
  letter-spacing: 0;
  line-height: normal;
  margin-left: 1.0px;
  margin-top: 2px;
  min-height: 21px;
  min-width: 55px;
  opacity: 0.5;
  text-align: center;
}

.group-33396 {
  align-items: flex-start;
  align-self: flex-start;
  display: flex;
  flex-direction: column;
  // gap: 8px;
  margin-top: 11px;
  // min-height: 82px;
  width: 92px;
}

.frame-33395-2 {
  min-width: 69px;
}

.classes-1 {
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  min-height: 21px;
  min-width: 55px;
  opacity: 0.5;
}

.remaining {
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  min-height: 21px;
  min-width: 76px;
  opacity: 0.5;
}

.group-33395 {
  align-items: flex-start;
  display: flex;
  gap: 8px;
  min-width: 88px;
}

.ellipse-1 {
  background-color: var(--button-green);
  border-radius: 12px;
  height: 20px;
  width: 20px;
}

.ellipse-1-1 {
  background-color: var(--edgewater);
  border-radius: 12px;
  height: 20px;
  width: 20px;
}

.group-33398 {
  align-items: flex-start;
  align-self: flex-start;
  display: flex;
  flex-direction: column;
  // gap: 8px;
  margin-top: 11px;
  // min-height: 82px;
  width: 120px;
}

.frame-33395-3 {
  min-width: 71px;
}

.remaining-1 {
  min-width: 76px;
}

.group-33397 {
  align-items: flex-start;
  display: flex;
  gap: 8px;
  min-width: 120px;
}

.ellipse-20 {
  background-color: var(--black);
  border-radius: 6.5px/1.5px;
  height: 3px;
  left: 197px;
  opacity: 0.2;
  position: absolute;
  top: 108px;
  width: 13px;
}

.ellipse-15-3 {
  border-radius: 57.5px;
  height: 85px;
  width: 85px;
}

.frame-33395-4 {
  align-items: flex-start;
  display: flex;
  gap: 8px;
  height: 21px;
}

.frame-33397-3 {
  align-items: flex-start;
  display: flex;
  gap: 8px;
  height: 21px;
  margin-top: 16px;
}

.group-33392-3 {
  align-items: flex-start;
  border-radius: 57.5px;
  display: flex;
  height: 85px;
  left: 0;
  min-width: 85px;
  position: absolute;
  top: 0;
}

.logout-3 {
  height: 24px;
  width: 24px;
}


// anima indivi styles


// anima styles for DCO school

/* screen - frame-33476 */

.frame-33476 {
  align-items: flex-start;
  border: 1px none;
  display: flex;
  flex-direction: column;
  // overflow: scroll;
  // min-height: 2124px;
  // width: 5780px;
}

.frame-33476 .tag_02 {
  align-items: flex-start;
  background-color: var(--black);
  display: flex;
  height: 228px;
  padding: 23px 188px;
  width: 1734px;
}

.frame-33476 .title-1 {
  letter-spacing: 0;
  line-height: normal;
  min-height: 180px;
  min-width: 1358px;
  text-align: center;
}

.frame-33476 .flex-row-1 {
  align-items: flex-start;
  display: flex;
  gap: 100px;
  margin-top: 196px;
  min-width: 5780px;
}

.frame-33476 .flex-row-item {
  align-items: center;
  background-color: var(--white);
  // border-radius: 30px;
  // display: flex;
  flex-direction: column;
  // height: 844px;
  // overflow: hidden;
  width: 100%;
  text-align: center;
  display: flex;
}

.frame-33476 .frame {
  height: 176px;
  margin-top: 49px;
  width: 222px;
}

.frame-33476 .overlap-group1-3 {
  align-items: center;
  background-color: var(--mountain-meadow);
  border-radius: 30px 30px 0 0;
  display: flex;
  flex-direction: column;
  margin-top: 22px;
  min-height: 100%;
  padding: 39px 0;
  width: 100%;
}

.frame-33476 .sign-in {
  letter-spacing: 0;
  line-height: normal;
  margin-left: 10px;
  /* min-height: 36px; */
  /* min-width: 89px; */
  text-align: center;
  /* margin: auto; */
}

.frame-33476 .group-33362 {
  align-items: flex-start;
  display: flex;
  gap: 4px;
  // height: 36px;
  /* margin-left: 5px; */
  margin-top: 9px;
  /* min-width: 261px; */
  /* margin: 0px auto; */
}

.frame-33476 .text-34 {
  align-self: center;
  color: var(--green-haze);
  font-family: var(--font-family-poppins);
  font-size: var(--font-size-xxxl);
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  margin-bottom: 2.0px;
  min-height: 24px;
  min-width: 24px;
  text-align: center;
}

.frame-33476 .hi-welcome-back {
  color: var(--white);
  font-family: var(--font-family-poppins);
  font-size: var(--font-size-xxxl);
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  min-height: 36px;
  // min-width: 229px;
  text-align: center;
}

.frame-33476 .please-login-your-account {
  letter-spacing: 0;
  line-height: normal;
  margin-left: 1.0px;
  margin-top: 15px;
  min-height: 24px;
  min-width: 207px;
  opacity: 0.5;
  text-align: center;
}

.frame-33476 .overlap-group-12 {
  align-items: center;
  background-color: var(--white);
  border-radius: 50px;
  display: flex;
  height: 58px;
  margin-top: 24px;
  // min-width: 352px;
  padding: 14.2px 24.2px;
  width: calc(100% - 20px);
}

.frame-33476 .circle-flags-us-1 {
  align-self: flex-end;
  height: 28px;
  width: 23px;
}

.frame-33476 .linh-nguyen {
  letter-spacing: 0;
  line-height: 26px;
  margin-left: 6px;
  white-space: nowrap;
  width: 17px;
}

.frame-33476 .icon {
  height: 19px;
  margin-left: 5px;
  margin-top: 0.5px;
  width: 16px;
}

.frame-33476 .linh-nguyen-6 {
  margin-left: 20px;
  margin-top: 1.0px;
  min-height: 21px;
  width: 100%;
  opacity: 0.5;
}

.frame-33476 .botton-2 {
  background-color: var(--button-green);
  border-radius: 50px;
  height: 56px;
  margin-top: 39px;
  overflow: hidden;
  padding: 12px 129px;
  width: calc(100% - 20px);
  justify-content: center;
  cursor: pointer;
}

.frame-33476 .no-show-1 {
  min-width: 100px;
}

.frame-33476 .otp_-verification {
  align-items: flex-start;
  background-color: var(--white);
  // border-radius: 30px;
  display: flex;
  flex-direction: column;
  height: 844px;
  overflow: hidden;
  width: 100%;
}

.frame-33476 .flex-row-2 {
  align-items: center;
  display: flex;
  gap: 52px;
  height: 36px;
  margin-left: 20px;
  margin-top: 12px;
  min-width: 274px;
}

.frame-33476 .logout {
  height: 24px;
  margin-top: 2.0px;
  width: 24px;
}

.frame-33476 .otp-verification {
  letter-spacing: 0;
  line-height: normal;
  min-height: 36px;
  min-width: 198px;
  text-align: center;
}

.frame-33476 .please-enter-your-in {
  align-self: center;
  letter-spacing: 0;
  line-height: normal;
  margin-left: 1.0px;
  margin-top: 43px;
  min-height: 48px;
  opacity: 0.5;
  text-align: center;
  width: 293px;
}

.frame-33476 .overlap-group4 {
  align-items: center;
  background-color: var(--mountain-meadow);
  border-radius: 30px;
  display: flex;
  flex-direction: column;
  margin-top: 52px;
  min-height: -webkit-fill-available;
  padding: 2.5px 0;
  width: 100%;
}

.frame-33476 .group-33374 {
  align-items: flex-start;
  border-radius: 50px;
  display: flex;
  gap: 25px;
  padding: 10px;
  height: 56px;
  justify-content: center;
  margin-top: 38px;
  width: 70%;
}

.frame-33476 .overlap-group {
  align-items: flex-end;
  background-color: var(--white);
  border-radius: 50px;
  display: flex;
  height: 38px;
  margin-top: -0.75px;
  min-width: 38px;
  // padding: 0.8px 7.8px;
}

.frame-33476 .linh-nguyen-1 {
  min-height: 27px;
  min-width: 12px;
  opacity: 0.5;
}

.frame-33476 .linh-nguyen-2 {
  min-height: 27px;
  opacity: 0.5;
  // width: 8px;
}

.frame-33476 .group-33363 {
  align-items: flex-start;
  display: flex;
  gap: 14px;
  height: 36px;
  margin-left: 4.0px;
  margin-top: 26px;
  min-width: 164px;
}

.frame-33476 .try-again-in {
  align-self: center;
  letter-spacing: 0;
  line-height: normal;
  // margin-bottom: 2.0px;
  // min-height: 24px;
  // min-width: 93px;
  opacity: 0.5;
  text-align: center;
}

.frame-33476 .text-36 {
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  min-height: 36px;
  min-width: 53px;
  text-align: center;
}

.frame-33476 .botton-3 {
  background-color: var(--button-green);
  border-radius: 50px;
  height: 56px;
  margin-top: 30px;
  overflow: hidden;
  padding: 12px 139px;
  width: 350px;
  cursor: pointer;
}

.frame-33476 .no-show-2 {
  min-width: 71px;
}

.frame-33476 .resend-otp {
  color: var(--white);
  font-family: var(--font-family-poppins);
  font-size: var(--font-size-xl);
  font-weight: 700;
  letter-spacing: 0;
  line-height: normal;
  margin-left: 1.0px;
  margin-top: 19px;
  min-height: 24px;
  min-width: 97px;
  opacity: 0.5;
  text-align: center;
  cursor: pointer;
}

.frame-33476 .i-os-numeric-keyboard {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  margin-top: 21px;
  min-height: 300px;
  width: 390px;
}

.frame-33476 .keys-layout-numeric-english-default {
  -webkit-backdrop-filter: blur(20.8px) brightness(100%);
  align-items: flex-start;
  backdrop-filter: blur(20.8px) brightness(100%);
  background-color: #ccced3c2;
  display: flex;
  flex-direction: column;
  gap: 7px;
  min-height: 226px;
  padding: 6.2px;
  width: 390px;
}

.frame-33476 .row-numbers {
  align-items: flex-end;
  display: flex;
  gap: 6px;
  min-width: 378px;
}

.frame-33476 .component-key {
  align-items: flex-start;
  background-color: var(--white);
  border-radius: 5.2px;
  box-shadow: 0px 1px 0px #898a8d;
  display: flex;
  flex-direction: column;
  height: 48px;
  overflow: hidden;
  width: 122px;
}

.frame-33476 .x {
  letter-spacing: 0;
  line-height: 30px;
  margin-top: -1px;
  min-height: 32px;
  text-align: center;
  white-space: nowrap;
  width: 122px;
}

.frame-33476 .x-1 {
  letter-spacing: 2.00px;
  line-height: 12px;
  min-height: 13px;
  text-align: center;
  white-space: nowrap;
  width: 122px;
}

.frame-33476 .component-key-1 {
  background-color: var(--white);
  border-radius: 5.2px;
  box-shadow: 0px 1px 0px #898a8d;
  flex-direction: column;
  margin-bottom: 0;
}

.frame-33476 .x-2 {
  letter-spacing: 0;
  line-height: 30px;
  margin-top: -1px;
  min-height: 32px;
  text-align: center;
  white-space: nowrap;
  width: 122px;
}

.frame-33476 .x-3 {
  letter-spacing: 2.00px;
  line-height: 12px;
  min-height: 13px;
  text-align: center;
  white-space: nowrap;
  width: 122px;
}

.frame-33476 .x-4 {
  letter-spacing: 0;
  line-height: 30px;
  margin-top: -1px;
  min-height: 32px;
  text-align: center;
  white-space: nowrap;
  width: 122px;
}

.frame-33476 .x-5 {
  letter-spacing: 2.00px;
  line-height: 12px;
  min-height: 13px;
  text-align: center;
  white-space: nowrap;
  width: 122px;
}

.frame-33476 .row-numbers-1 {
  align-items: flex-start;
  display: flex;
  gap: 6px;
  min-width: 378px;
}

.frame-33476 .x-6 {
  letter-spacing: 0;
  line-height: 30px;
  margin-top: -1px;
  min-height: 32px;
  text-align: center;
  white-space: nowrap;
  width: 122px;
}

.frame-33476 .x-7 {
  letter-spacing: 2.00px;
  line-height: 12px;
  min-height: 13px;
  text-align: center;
  white-space: nowrap;
  width: 122px;
}

.frame-33476 .component-key-2 {
  background-color: var(--white);
  border-radius: 5.2px;
  box-shadow: 0px 1px 0px #898a8d;
  flex-direction: column;
  margin-top: 0;
}

.frame-33476 .x-8 {
  letter-spacing: 0;
  line-height: 30px;
  margin-top: -1px;
  min-height: 32px;
  text-align: center;
  white-space: nowrap;
  width: 122px;
}

.frame-33476 .x-9 {
  letter-spacing: 2.00px;
  line-height: 12px;
  min-height: 13px;
  text-align: center;
  white-space: nowrap;
  width: 122px;
}

.frame-33476 .x-10 {
  letter-spacing: 0;
  line-height: 30px;
  margin-top: -1px;
  min-height: 32px;
  text-align: center;
  white-space: nowrap;
  width: 122px;
}

.frame-33476 .x-11 {
  letter-spacing: 2.00px;
  line-height: 12px;
  min-height: 13px;
  text-align: center;
  white-space: nowrap;
  width: 122px;
}

.frame-33476 .x-12 {
  letter-spacing: 0;
  line-height: 30px;
  margin-top: -1px;
  min-height: 32px;
  text-align: center;
  white-space: nowrap;
  width: 122px;
}

.frame-33476 .x-13 {
  letter-spacing: 2.00px;
  line-height: 12px;
  min-height: 13px;
  text-align: center;
  white-space: nowrap;
  width: 122px;
}

.frame-33476 .x-14 {
  letter-spacing: 0;
  line-height: 30px;
  margin-top: -1px;
  min-height: 32px;
  text-align: center;
  white-space: nowrap;
  width: 122px;
}

.frame-33476 .x-15 {
  letter-spacing: 2.00px;
  line-height: 12px;
  min-height: 13px;
  text-align: center;
  white-space: nowrap;
  width: 122px;
}

.frame-33476 .x-16 {
  letter-spacing: 0;
  line-height: 30px;
  margin-top: -1px;
  min-height: 32px;
  text-align: center;
  white-space: nowrap;
  width: 122px;
}

.frame-33476 .x-17 {
  letter-spacing: 2.00px;
  line-height: 12px;
  min-height: 13px;
  text-align: center;
  white-space: nowrap;
  width: 122px;
}

.frame-33476 .component-key-3 {
  border-radius: 4.78px;
  padding: 7.9px 7.3px;
}

.frame-33476 .x-18 {
  letter-spacing: 0;
  line-height: 28px;
  min-height: 30px;
  text-align: center;
  white-space: nowrap;
  width: 107px;
}

.frame-33476 .x-19 {
  letter-spacing: 0;
  line-height: 30px;
  margin-top: -1px;
  min-height: 32px;
  text-align: center;
  white-space: nowrap;
  width: 122px;
}

.frame-33476 .x-20 {
  letter-spacing: 2.00px;
  line-height: 12px;
  min-height: 13px;
  text-align: center;
  white-space: nowrap;
  width: 122px;
}

.frame-33476 .component-key-4 {
  border-radius: 4.78px;
  margin-top: 0;
  padding: 7.9px 7.3px;
}

.frame-33476 .text-37 {
  letter-spacing: 0;
  line-height: 28px;
  min-height: 30px;
  text-align: center;
  white-space: nowrap;
  width: 107px;
}

.frame-33476 .component-home-indicator-section {
  -webkit-backdrop-filter: blur(20.8px) brightness(100%);
  align-items: flex-end;
  backdrop-filter: blur(20.8px) brightness(100%);
  background-color: #ccced3c2;
  display: flex;
  height: 74px;
  min-width: 390px;
  padding: 9.4px 125.3px;
}

.frame-33476 .indicator {
  background-color: var(--black);
  border-radius: 5.2px;
  height: 5px;
  width: 139px;
}

.frame-33476 .edit_-profile {
  align-items: center;
  background-color: var(--white);
  // border-radius: 30px;
  display: flex;
  flex-direction: column;
  // height: 1094px;
  overflow: hidden;
  width: 100%;
  padding-bottom: 20px;
}

.frame-33476 .overlap-group-1 {
  // height: 256px;
  position: relative;
  width: 100%;
  justify-content: center;
  display: flex;
  align-items: center;
}

.frame-33476 .rectangle-5418 {
  height: 130px;
  // left: 0;
  // position: absolute;
  // top: 0;
  width: 100%;
  background-color: var(--mountain-meadow);
}

.frame-33476 .edit-profile {
  left: 40%
}

.frame-33476 .logout-1 {
  height: 24px;
  left: 20px;
  position: absolute;
  top: 22px;
  width: 24px;
  background: white;
  border-radius: 50%;
  padding: 2px;
  // transform: rotate(180deg);
}

.frame-33476 .edit-1 {
  height: 25px;
  right: 25px;
  color: white;
  position: absolute;
  top: 22px;
  width: 25px;
  filter: brightness(0%);
  // transform: rotate(180deg);
}

.frame-33476 .ellipse-11 {
  height: 132px;
  /* left: 129px; */
  object-fit: cover;
  position: absolute;
  top: 55px;
  width: 132px;
  border-radius: 50%;
  background-color: rgb(245, 227, 227);
}

.frame-33476 .address-3 {
  margin-left: 1px;
  margin-top: 60px;
  /* min-height: 27px; */
  min-width: 157px;
  text-align: center;
}

.frame-33476 .group-33364 {
  align-items: flex-start;
  display: flex;
  gap: 8px;
  height: 21px;
  margin-left: 2.0px;
  margin-top: 9px;
  min-width: 118px;
  justify-content: center;
}

.frame-33476 .group-33354 {
  height: 14px;
  margin-top: 1px;
  width: 86px;
}

.frame-33476 .text-1 {
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  min-height: 21px;
  min-width: 22px;
  opacity: 0.5;
  text-align: center;
}

.frame-33476 .group-33366 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-left: 20px;
  margin-top: 10px;
  /* min-height: 85px; */
  width: 100%;
}

.frame-33476 .driving-school-name {
  letter-spacing: 0;
  line-height: normal;
  /* margin-top: -1px; */
  /* min-height: 1px; */
  display: flex;
  // justify-content: center;
  text-align: left;
  gap: 10px;
  // align-items: center;
  width: 100%;
  padding-right: 20px;
}

.frame-33476 .overlap-group1 {
  border-radius: 50px;
  height: 58px;
  margin-left: -0.75px;
  position: relative;
  width: 352px;
}

.frame-33476 .overlap-group-2 {
  align-items: flex-end;
  background-color: var(--white);
  border-radius: 50px;
  display: flex;
  height: 58px;
  left: 0;
  min-width: 352px;
  padding: 17.8px 56.8px;
  position: absolute;
  top: 0;
}

.frame-33476 .linh-nguyen-7 {
  min-height: 21px;
  min-width: 120px;
}

.frame-33476 .steering-wheel-1 {
  height: 16px;
  left: 25px;
  /* position: absolute; */
  top: 17px;
  width: 16px;
  min-width: 16px;
}

.frame-33476 .overlap-group7 {
  height: 85px;
  margin-left: 2.0px;
  margin-top: 11px;
  position: relative;
  width: 352px;
}

.frame-33476 .group-33367 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 8px;
  left: 0;
  min-height: 85px;
  position: absolute;
  top: 0;
  width: 352px;
}

.frame-33476 .contact-name {
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  min-height: 21px;
}

.frame-33476 .overlap-group-13 {
  align-items: flex-end;
  background-color: var(--white);
  border-radius: 50px;
  display: flex;
  height: 58px;
  margin-left: -0.75px;
  min-width: 352px;
  padding: 17.8px 56.8px;
}

.frame-33476 .linh-nguyen-8 {
  min-height: 21px;
  min-width: 100px;
}

.frame-33476 .contact_mail {
  height: 24px;
  left: 24px;
  position: absolute;
  top: 45px;
  width: 24px;
}

.frame-33476 .email-address {
  align-self: flex-start;
  letter-spacing: 0;
  line-height: normal;
  margin-left: 20px;
  margin-top: 20px;
  min-height: 21px;
}

.frame-33476 .overlap-group-3 {
  border-radius: 50px;
  height: 58px;
  margin-top: 8px;
  position: relative;
  width: 352px;
}

.frame-33476 .linh-nguyen-3 {
  min-height: 21px;
  min-width: 207px;
}

.frame-33476 .vuesaxboldsms {
  height: 24px;
  left: 25px;
  position: absolute;
  top: 17px;
  width: 24px;
}

.frame-33476 .office-address {
  align-self: flex-start;
  letter-spacing: 0;
  line-height: normal;
  margin-left: 20px;
  margin-top: 14px;
  min-height: 21px;
}

.frame-33476 .overlap-group-14 {
  align-items: flex-end;
  background-color: var(--white);
  border-radius: 50px;
  display: flex;
  height: 58px;
  justify-content: flex-end;
  left: 0;
  min-width: 352px;
  padding: 17.8px 2.8px;
  position: absolute;
  top: 0;
}

.frame-33476 .frame-10 {
  align-items: flex-start;
  display: flex;
  height: 21px;
  // overflow: hidden;
  width: 292px;
}

.frame-33476 .linh-nguyen-9 {
  margin-top: -1px;
  min-height: 21px;
}

.frame-33476 .vuesaxboldlocation {
  height: 24px;
  left: 25px;
  position: absolute;
  top: 17px;
  width: 24px;
}

.frame-33476 .overlap-group6 {
  align-items: center;
  // background-image: url(./assets/img/mapsicle-map-1@2x.png);
  background-size: 100% 100%;
  display: flex;
  flex-direction: column;
  gap: 45px;
  // margin-top: 29px;
  min-height: 326px;
  padding: 16px 0;
  width: 390px;
}

.frame-33476 .overlap-group4-1 {
  background-color: #1dc78b1f;
  border-radius: 84px;
  height: 168px;
  margin-left: 10.0px;
  position: relative;
  width: 168px;
}

.frame-33476 .ellipse-6 {
  background-color: var(--mountain-meadow);
  border-radius: 55px;
  height: 110px;
  left: 29px;
  opacity: 0.15;
  position: absolute;
  top: 29px;
  width: 110px;
}

.frame-33476 .icon-location_pin {
  height: 34px;
  left: 72px;
  position: absolute;
  top: 67px;
  width: 24px;
}

.frame-33476 .botton-4 {
  background-color: var(--button-green);
  border-radius: 50px;
  height: 56px;
  overflow: hidden;
  padding: 12px 153px;
  width: 350px;
}

.frame-33476 .no-show {
  letter-spacing: 0;
  line-height: normal;
  margin-left: 1px;
  min-height: 30px;
  // min-width: 43px;
  text-align: center;
}

.frame-33476 .edit-car {
  left: 26%;
}

.frame-33476 .ellipse-11-1 {
  height: 132px;
  position: absolute;
  top: 70px;
  width: 132px;
  left: 0px;
  right: 0px;
  margin: auto;
  background: #eceff1;
  border-radius: 50%;
  padding: 10px;
}

.frame-33476 .address {
  letter-spacing: 0;
  line-height: normal;
  margin-left: 1px;
  margin-top: 55px;
  /* min-height: 27px; */
  /* min-width: 175px; */
  text-align: center;
}

.frame-33476 .frame-33372 {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-top: 10px;
  // min-height: 196px;
  width: 100%;
  padding: 0 20px
}

.frame-33476 .frame-11 {
  align-items: flex-start;
  background-color: var(--aqua-squeeze);
  border-radius: 30px;
  display: flex;
  gap: 204px;
  height: 60px;
  min-width: 350px;
  padding: 17px 16px;
}

.frame-33476 .car-details {
  min-height: 24px;
  min-width: 90px;
}

.frame-33476 .vuesaxboldarrow-circle-down {
  align-self: center;
  height: 24px;
  width: 24px;
}

.frame-33476 .frame-12 {
  align-items: flex-start;
  background-color: var(--aqua-squeeze);
  border-radius: 30px;
  display: flex;
  gap: 176px;
  height: 60px;
  min-width: 350px;
  padding: 17px 16px;
}

.frame-33476 .course-details {
  letter-spacing: 0;
  line-height: normal;
  min-height: 24px;
  min-width: 118px;
}

.frame-33476 .frame-13 {
  align-items: flex-start;
  background-color: var(--aqua-squeeze);
  border-radius: 30px;
  display: flex;
  gap: 237px;
  height: 60px;
  min-width: 350px;
  padding: 17px 16px;
}

.frame-33476 .pricing {
  letter-spacing: 0;
  line-height: normal;
  min-height: 24px;
  min-width: 57px;
}

.frame-33476 .group-33373-1 {
  margin-right: 6.0px;
  margin-top: 26px;
  min-width: 344px;
}

.frame-33476 .overlap-group-4 {
  align-items: center;
  background-color: var(--mercury);
  border-radius: 15px;
  display: flex;
  height: 82px;
  margin-left: -1px;
  margin-top: -0.75px;
  min-width: 82px;
  padding: 0 24.8px;
}

.frame-33476 .x-svgrepo-com-1 {
  height: 32px;
  width: 32px;
}

.frame-33476 .rectangle-5420 {
  height: 80px;
  margin-left: 7px;
  width: 80px;
}

.frame-33476 .rectangle-542 {
  height: 80px;
  margin-left: 8px;
  width: 80px;
}

.frame-33476 .botton-5 {
  background-color: var(--button-green);
  border-radius: 50px;
  height: 56px;
  margin-top: 25px;
  // overflow: hidden;
  padding: 12px 80px;
  width: 90%;
}

.frame-33476 .no-show-3 {
  min-width: 100%;
}

.frame-33476 .botton-container-1 {
  align-items: flex-start;
  display: flex;
  gap: 10px;
  margin-top: 24px;
  min-width: 350px;
  margin: 20px
}

.frame-33476 .botton {
  align-items: flex-start;
  background-color: var(--button-green);
  border-radius: 50px;
  display: flex;
  height: 56px;
  overflow: hidden;
  padding: 12px 49px;
  width: 170px;
}

.frame-33476 .no-show-4 {
  min-width: 71px;
}

.frame-33476 .botton-1 {
  background-color: var(--cardinal);
  border-radius: 50px;
  height: 56px;
  overflow: hidden;
  padding: 11.5px 49px;
  // width: 170px;
}

.frame-33476 .cancel {
  letter-spacing: 0;
  line-height: normal;
  margin-left: 1px;
  min-height: 30px;
  // min-width: 71px;
  text-align: center;
}

.frame-33476 .edit_-car_-profile_0 {
  height: 1026px;
}

.frame-33476 .group-33354-1 {
  align-self: center;
  height: 14px;
  margin-bottom: 2.14px;
  width: 86px;
}

.frame-33476 .frame-33370 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-top: 32px;
  min-height: 442px;
  width: 350px;
}

.frame-33476 .frame-11-1 {
  align-items: flex-end;
  border-radius: 30px;
  flex-direction: column;
  // min-height: 306px;
  padding: 16px 14px;
  width: 350px;
  text-align: left;
}

.frame-33476 .flex-row-3 {
  align-items: flex-start;
  align-self: center;
  display: flex;
  gap: 204px;
  height: 25px;
  margin-top: 1px;
  min-width: 318px;
}

.frame-33476 .vuesaxboldarrow-circle-down-1 {
  align-self: flex-end;
  height: 24px;
  width: 24px;
}

.frame-33476 .group-33367-1 {
  gap: 32px;
  // height: 40px;
  margin-top: 16px;
  min-width: 320px;
}

.frame-33476 .car-make {
  align-self: center;
  margin-bottom: 1.0px;
  min-height: 21px;
  min-width: 118px;
}

.frame-33476 .overlap-group-5 {
  align-items: center;
  justify-content: center;
  background-color: var(--white);
  border-radius: 50px;
  display: flex;
  // height: 42px;
  // margin-top: -0.75px;
  min-width: 180px;
  // padding: 0px 20.8px;
}

.frame-33476 .linh-nguyen-10 {
  min-height: 21px;
  min-width: 37px;
}

.frame-33476 .group-33368 {
  align-items: flex-start;
  display: flex;
  gap: 27px;
  // height: 40px;
  margin-top: 8px;
  min-width: 320px;
}

.frame-33476 .car-model {
  align-self: center;
  margin-bottom: 1.0px;
  min-height: 21px;
  min-width: 118px;
}

.frame-33476 .linh-nguyen-11 {
  min-height: 21px;
  min-width: 55px;
}

.frame-33476 .group-33369 {
  align-items: flex-start;
  display: flex;
  gap: 3px;
  // height: 40px;
  margin-top: 8px;
  min-width: 320px;
}

.frame-33476 .car-mgf-year {
  align-self: center;
  letter-spacing: 0;
  line-height: normal;
  margin-bottom: 1.0px;
  min-height: 21px;
  min-width: 118px;
}

.frame-33476 .linh-nguyen-12 {
  min-height: 21px;
  min-width: 33px;
}

.frame-33476 .group-33370 {
  align-items: flex-start;
  display: flex;
  gap: 12px;
  // height: 40px;
  margin-top: 8px;
  min-width: 320px;
}

.frame-33476 .car-number {
  align-self: center;
  margin-bottom: 1.0px;
  min-height: 21px;
  min-width: 118px;
}

.frame-33476 .linh-nguyen-13 {
  min-height: 21px;
  min-width: 81px;
}

.frame-33476 .group-33371 {
  align-items: center;
  display: flex;
  gap: 20px;
  margin-top: 8px;
  min-width: 320px;
}

.frame-33476 .file {
  letter-spacing: 0;
  line-height: normal;
  margin-bottom: 1.0px;
  min-height: 21px;
  min-width: 106px;
}

.frame-33476 .overlap-group-15 {
  align-items: flex-end;
  background-color: var(--mountain-meadow);
  border-radius: 50px;
  display: flex;
  // height: 40px;
  justify-content: flex-end;
  min-width: 178px;
  text-align: center;

  label {
    padding: 6px 20px;
    width: 190px;
  }
}

.frame-33476 .linh-nguyen-14 {
  min-height: 21px;
  min-width: 51px;
}

.frame-33476 .group-33367-2 {
  margin-top: 26px;
  min-width: 350px;
}

.frame-33476 .rectangle-5420-1 {
  height: 80px;
  margin-left: 9px;
  width: 80px;
}

.frame-33476 .rectangle-542-1 {
  height: 80px;
  margin-left: 10px;
  width: 80px;
}

.frame-33476 .botton-container {
  gap: 10px;
  margin-top: 32px;
  min-width: 350px;
}

.frame-33476 .no-show-5 {
  min-width: 71px;
}

.frame-33476 .cancel-1 {
  margin-left: 1px;
  min-height: 30px;
  min-width: 71px;
}

.frame-33476 .frame-33374 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 26px;
  margin-top: 32px;
  min-height: 548px;
  width: 350px;
}

.frame-33476 .frame-33372-1 {
  min-height: 442px;
}

.frame-33476 .frame-12-1 {
  align-items: flex-end;
  background-color: var(--aqua-squeeze);
  border-radius: 30px;
  display: flex;
  flex-direction: column;
  // min-height: 306px;
  padding: 15.2px 14px;
  width: 350px;
  text-align: left;
}

.frame-33476 .flex-row-4 {
  align-items: flex-start;
  align-self: center;
  display: flex;
  gap: 176px;
  height: 25px;
  margin-top: 2px;
  min-width: 318px;
}

.frame-33476 .group-33369-1 {
  align-items: flex-start;
  display: flex;
  gap: 24px;
  // height: 40px;
  margin-top: 16px;
  min-width: 320px;
}

.frame-33476 .seats-offered {
  align-self: center;
  letter-spacing: 0;
  line-height: normal;
  margin-bottom: 1.0px;
  min-height: 21px;
  min-width: 105px;
}

.frame-33476 .overlap-group-16 {
  align-items: flex-end;
  background-color: var(--white);
  border-radius: 50px;
  display: flex;
  height: 42px;
  justify-content: flex-end;
  margin-top: -0.75px;
  min-width: 130px;
  padding: 9.8px 59.8px;
}

.frame-33476 .linh-nguyen-15 {
  min-height: 21px;
  min-width: 9px;
  text-align: center;
}

.frame-33476 .group-33370-1 {
  align-items: flex-start;
  display: flex;
  gap: 31px;
  // height: 40px;
  margin-top: 8px;
  min-width: 320px;
}

.frame-33476 .kms-per-day {
  align-self: center;
  letter-spacing: 0;
  line-height: normal;
  margin-bottom: 1.0px;
  min-height: 21px;
  min-width: 98px;
}

.frame-33476 .overlap-group-17 {
  align-items: flex-end;
  background-color: var(--white);
  border-radius: 50px;
  display: flex;
  // height: 42px;
  justify-content: flex-end;
  margin-top: -0.75px;
  min-width: 130px;
  // padding: 9.8px 53.8px;
}

.frame-33476 .linh-nguyen-16 {
  min-height: 21px;
  min-width: 21px;
  text-align: center;
}

.frame-33476 .group-33371-1 {
  // gap: 60px;
  margin-top: 8px;
}

.frame-33476 .hours-per-day {
  align-self: center;
  letter-spacing: 0;
  line-height: normal;
  margin-bottom: 1.0px;
  min-height: 21px;
  min-width: 129px;
}

.frame-33476 .overlap-group-6 {
  align-items: flex-end;
  background-color: var(--white);
  border-radius: 50px;
  display: flex;
  // height: 42px;
  justify-content: flex-end;
  margin-top: -0.75px;
  min-width: 130px;
  // padding: 9.8px 55.8px;
}

.frame-33476 .linh-nguyen-4 {
  min-height: 21px;
  min-width: 17px;
  text-align: center;
}

.frame-33476 .group-33372 {
  align-items: flex-start;
  display: flex;
  gap: 16px;
  // height: 40px;
  margin-top: 8px;
  min-width: 320px;
}

.frame-33476 .total-numbers-of-days {
  align-self: center;
  letter-spacing: 0;
  line-height: normal;
  margin-bottom: 1.0px;
  min-height: 21px;
  min-width: 115px;
}

.frame-33476 .overlap-group1-4 {
  align-self: center;
  height: 30px;
  margin-left: 0.75px;
  margin-top: 7px;
  position: relative;
  width: 319px;
}

.frame-33476 .validity-numbers-of-days {
  left: 0;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 10px;
}

.frame-33476 .overlap-group-18 {
  align-items: flex-end;
  // background-color: var(--white);
  border-radius: 50px;
  display: flex;
  // height: 42px;
  left: 189px;
  min-width: 130px;
  // padding: 9.8px 55.8px;
  position: absolute;
  top: 0;
}

.frame-33476 .linh-nguyen-17 {
  min-height: 21px;
  min-width: 18px;
  text-align: center;
}

.frame-33476 .group-33373 {
  align-items: flex-start;
  display: flex;
  min-width: 344px;
}

.frame-33476 .no-show-6 {
  min-width: 71px;
}

.frame-33476 .cancel-2 {
  margin-left: 1px;
  min-height: 30px;
  min-width: 71px;
}

.frame-33476 .edit_-car_-profile_04 {
  height: 934px;
}

.frame-33476 .frame-33374-1 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 26px;
  margin-top: 32px;
  min-height: 454px;
  width: 350px;
}

.frame-33476 .frame-33372-2 {
  min-height: 348px;
}

.frame-33476 .overlap-group1-5 {
  align-items: flex-end;
  background-color: var(--aqua-squeeze);
  border-radius: 30px;
  display: flex;
  flex-direction: column;
  // min-height: 212px;
  padding: 16px 14px;
  width: 350px;
  text-align: left;
}

.frame-33476 .flex-row-5 {
  align-items: flex-start;
  align-self: center;
  display: flex;
  gap: 237px;
  height: 25px;
  margin-top: 1px;
  min-width: 318px;
}

.frame-33476 .group-33371-2 {
  gap: 90px;
  margin-top: 16px;
}

.frame-33476 .private-drive {
  align-self: center;
  letter-spacing: 0;
  line-height: normal;
  margin-bottom: 1.0px;
  min-height: 21px;
  min-width: 99px;
}

.frame-33476 .overlap-group-7 {
  align-items: flex-end;
  background-color: var(--white);
  border-radius: 50px;
  display: flex;
  // height: 42px;
  justify-content: flex-end;
  margin-top: -0.75px;
  min-width: 184px;
  // padding: 9.8px 46.8px;
}

.frame-33476 .linh-nguyen-5 {
  min-height: 21px;
  min-width: 35px;
  text-align: center;
}

.frame-33476 .group-33372-1 {
  align-items: flex-start;
  display: flex;
  gap: 38px;
  // height: 40px;
  margin-top: 8px;
  min-width: 320px;
}

.frame-33476 .shared-drive {
  align-self: center;
  letter-spacing: 0;
  line-height: normal;
  margin-bottom: 1.0px;
  min-height: 21px;
  min-width: 101px;
}

.frame-33476 .overlap-group-19 {
  align-items: flex-end;
  background-color: var(--white);
  border-radius: 50px;
  display: flex;
  // height: 42px;
  // margin-top: -0.75px;
  min-width: 130px;
  // padding: 9.8px 46.8px;
}

.frame-33476 .linh-nguyen-18 {
  min-height: 21px;
  min-width: 36px;
  text-align: center;
}

.frame-33476 .group-33373-2 {
  // gap: 54px;
  margin-top: 8px;
  min-width: 320px;
}

.frame-33476 .learners-license-d {
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  min-height: 42px;
  min-width: 135px;
}

.frame-33476 .no-show-7 {
  min-width: 71px;
}

.frame-33476 .cancel-3 {
  margin-left: 1px;
  min-height: 30px;
  min-width: 71px;
}

.frame-33476 .frame-33441 {
  align-items: center;
  display: flex;
  gap: 22%;
  // height: 36px;
  // margin-left: 3.0px;
  margin-top: 13px;
  min-width: 100%;
  // margin-left: 23px;
  justify-content: center;
}

.frame-33476 .menu {
  height: 24px;
  width: 24px;
}

.frame-33476 .dashboard {
  align-self: flex-start;
  letter-spacing: 0;
  line-height: normal;
  // margin-top: -1px;
  // min-height: 36px;
  min-width: 141px;
  text-align: center;
}

.frame-33476 .logout-2 {
  align-items: flex-start;
  display: flex;
  min-width: 24px;
  // transform: rotate(-3.14deg);
}

.frame-33476 .frame-1 {
  align-items: flex-start;
  background-image: url(./assets/img/group-1@2x.png);
  background-size: 100% 100%;
  display: flex;
  min-width: 24px;
}

.frame-33476 .tab_buttons-container {
  align-items: flex-start;
  display: flex;
  gap: 8px;
  margin-top: 16px;
  min-width: 100%;
  justify-content: center;
}

.frame-33476 .tab_-buttons {
  align-items: flex-start;
  background-color: var(--mountain-meadow);
  border-radius: 30px;
  display: flex;
  height: 48px;
  justify-content: center;
  min-width: 45%;
  padding: 11px 67px;
}

.frame-33476 .today {
  letter-spacing: 0;
  line-height: normal;
  min-height: 23px;
  min-width: 36px;
  text-align: center;
}

.frame-33476 .tab_-buttons-1 {
  background-color: var(--concrete);
  opacity: 0.4;
  padding: 11px 23px;
  border-radius: 30px;
}

.frame-33476 .today-1 {
  min-width: 64px;
  // opacity: 0.5;
}

.frame-33476 .frame-33442 {
  align-items: center;
  background-color: var(--mountain-meadow);
  display: flex;
  flex-direction: column;
  margin-right: 0.72px;
  margin-top: 16px;
  min-height: 147px;
  padding: 18px 6.9px;
  width: 100%;
  justify-content: center;
}

.frame-33476 .title {
  align-items: center;
  display: flex;
  height: 22px;
  margin-right: 11.11px;
  min-width: 351px;
}

.frame-33476 .name {
  align-self: flex-start;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -0.9px;
  min-height: 22px;
  min-width: 72px;
}

.frame-33476 .icon-1 {
  height: 8px;
  margin-bottom: 3.0px;
  margin-left: 216px;
  width: 4px;
}

.frame-33476 .icon-2 {
  height: 8px;
  margin-bottom: 3.0px;
  margin-left: 54px;
  width: 4px;
}

.frame-33476 .days {
  align-items: flex-start;
  display: flex;
  height: 16px;
  margin-top: 13px;
  min-width: 362px;
}

.frame-33476 .mon {
  letter-spacing: 0;
  line-height: normal;
  margin-top: -0.9px;
  min-height: 16px;
  min-width: 24px;
}

.frame-33476 .tue {
  letter-spacing: 0;
  line-height: normal;
  margin-left: 35px;
  margin-top: -0.9px;
  min-height: 16px;
  min-width: 20px;
}

.frame-33476 .wed {
  letter-spacing: 0;
  line-height: normal;
  margin-left: 34px;
  margin-top: -0.9px;
  min-height: 16px;
  min-width: 25px;
}

.frame-33476 .thu {
  letter-spacing: 0;
  line-height: normal;
  margin-left: 33px;
  margin-top: -0.9px;
  min-height: 16px;
  min-width: 21px;
}

.frame-33476 .fri {
  letter-spacing: 0;
  line-height: normal;
  margin-left: 34px;
  margin-top: -0.9px;
  min-height: 16px;
  min-width: 13px;
}

.frame-33476 .sat {
  letter-spacing: 0;
  line-height: normal;
  margin-left: 35px;
  margin-top: -0.9px;
  min-height: 16px;
  min-width: 18px;
}

.frame-33476 .place {
  letter-spacing: 0;
  line-height: normal;
  margin-left: 35px;
  margin-top: -0.9px;
  min-height: 16px;
}

.frame-33476 .flex-row {
  align-items: center;
  align-self: center;
  display: flex;
  margin-left: 0.53px;
  margin-top: 24px;
  min-width: 336px;
}

.frame-33476 .number {
  letter-spacing: 0;
  line-height: normal;
  margin-top: 0.71px;
  min-height: 19px;
  min-width: 9px;
}

.frame-33476 .overlap-group-8 {
  height: 36px;
  margin-left: 34px;
  position: relative;
  width: 252px;
}

.frame-33476 .rectangle {
  height: 36px;
  left: 0;
  position: absolute;
  top: 0;
  width: 251px;
}

.frame-33476 .oval {
  height: 36px;
  left: 0;
  position: absolute;
  top: 0;
  width: 36px;
}

.frame-33476 .oval-1 {
  height: 36px;
  left: 215px;
  position: absolute;
  top: 0;
  width: 36px;
}

.frame-33476 .number-1 {
  left: 13px;
  position: absolute;
  top: 9px;
}

.frame-33476 .number-2 {
  left: 69px;
  position: absolute;
  top: 9px;
}

.frame-33476 .number-3 {
  left: 125px;
  position: absolute;
  top: 9px;
}

.frame-33476 .number-4 {
  left: 176px;
  position: absolute;
  top: 9px;
}

.frame-33476 .number-5 {
  left: 227px;
  position: absolute;
  top: 9px;
}

.frame-33476 .number-6 {
  margin-left: 30px;
  margin-top: 0.71px;
  min-height: 19px;
  min-width: 12px;
}

.frame-33476 .group-33404 {
  align-items: flex-start;
  display: flex;
  // gap: 20px;
  margin-left: 5.0px;
  margin-top: 10px;
  // min-width: 397px;
  // justify-content: left;
  overflow-x: auto;
  width: 100%;
  // border-bottom: solid 3px var(--mountain-meadow);
}

.frame-33476 .overlap-group-9 {
  height: 40px;
  position: relative;
  width: 111px;
  text-align: center;
}

.frame-33476 .x5 {
  left: 50px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 10px;
}

.frame-33476 .rectangle-5425 {
  border-radius: 9px 9px 0px 0px;
  height: 40px;
  left: 21px;
  position: absolute;
  top: 0;
  width: 70px;
}

.frame-33476 .line-4 {
  height: 1px;
  left: 0;
  position: absolute;
  top: 39px;
  width: 22px;
}

.frame-33476 .overlap-group-10 {
  align-items: center;
  background-color: var(--mountain-meadow);
  border-radius: 9px 9px 0px 0px;
  display: flex;
  height: 40px;
  justify-content: center;
  min-width: 90px;
  padding: 10px 10px;
}

.frame-33476 .x5-1 {
  min-height: 18px;
  min-width: 33px;
}

.frame-33476 .overlap-group-11 {
  height: 40px;
  position: relative;
  width: 186px;
}

.frame-33476 .x6 {
  left: 28px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 10px;
}

.frame-33476 .rectangle-5427 {
  border-radius: 9px 9px 0px 0px;
  height: 40px;
  left: 0;
  position: absolute;
  top: 0;
  width: 90px;
}

.frame-33476 .line-5 {
  height: 1px;
  left: 89px;
  position: absolute;
  top: 39px;
  width: 97px;
}

.frame-33476 .group-33379 {
  align-items: flex-start;
  align-self: flex-start;
  display: flex;
  gap: 13%;
  /* margin-left: 20px; */
  margin-top: 10px;
  min-width: 100%;
  justify-content: center;
}

.frame-33476 .group-3337 {
  align-items: flex-start;
  display: flex;
  gap: 8px;
  // height: 19px;
  min-width: 85px;
}

.frame-33476 .ellipse-12 {
  align-self: center;
  background-color: var(--button-green);
  border-radius: 8px;
  height: 10px;
  margin-top: 1.0px;
  width: 10px;
}

.frame-33476 .x26 {
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  min-height: 19px;
}

.frame-33476 .group-33376 {
  align-items: flex-start;
  display: flex;
  gap: 8px;
  height: 19px;
  min-width: 73px;
}

.frame-33476 .ellipse-12-1 {
  background-color: var(--carnation);
}

.frame-33476 .x26-1 {
  letter-spacing: 0;
  line-height: normal;
  // margin-top: -1px;
  // min-height: 19px;
}

.frame-33476 .ellipse-12-2 {
  background-color: #ff6c2e;
}

.frame-33476 .x26-2 {
  letter-spacing: 0;
  line-height: normal;
  // margin-top: -1px;
  // min-height: 19px;
}

.frame-33476 .overlap-group-container-1 {
  margin-top: 10px;
}

.frame-33476 .overlap-group19 {
  align-items: flex-end;
  background-color: var(--white);
  border-radius: 10px;
  display: flex;
  height: 40px;
  justify-content: flex-end;
  min-width: 110px;
  padding: 9px 25px;
}

.frame-33476 .x26-3 {
  letter-spacing: 0;
  line-height: normal;
  min-height: 21px;
  min-width: 59px;
  text-align: center;
}

.frame-33476 .overlap-group1-1 {
  align-items: flex-start;
  background-color: var(--white);
  border-radius: 10px;
  display: flex;
  height: 40px;
  justify-content: flex-end;
  min-width: 110px;
  padding: 9px 25px;
  width: 100%;
}

.frame-33476 .x26-4 {
  letter-spacing: 0;
  line-height: normal;
  min-height: 21px;
  min-width: 59px;
  text-align: center;
}

.frame-33476 .overlap-group1-2 {
  align-items: flex-start;
  background-color: var(--white);
  border-radius: 10px;
  display: flex;
  height: 40px;
  min-width: 110px;
  padding: 9px 25px;
}

.frame-33476 .x26-5 {
  letter-spacing: 0;
  line-height: normal;
  min-height: 21px;
  min-width: 60px;
  text-align: center;
}

.frame-33476 .overlap-group-container-2 {
  margin-top: 9px;
}

.frame-33476 .overlap-group17 {
  align-items: flex-start;
  background-color: var(--carnation) !important;
  border-radius: 10px;
  display: flex;
  height: 40px;
  justify-content: center;
  align-items: center;
  min-width: 109px;
  // padding: 10px 25px;
}

.frame-33476 .x26-6 {
  letter-spacing: 0;
  line-height: 10px;
  text-align: center;
  white-space: nowrap;
  width: 59px;
}

.frame-33476 .overlap-group15 {
  align-items: flex-start;
  background-color: var(--white);
  border-radius: 10px;
  display: flex;
  height: 40px;
  min-width: 110px;
  padding: 13px 25px;
}

.frame-33476 .x26-7 {
  letter-spacing: 0;
  line-height: 10px;
  min-height: 12px;
  text-align: center;
  white-space: nowrap;
  width: 60px;
}

.frame-33476 .overlap-group20 {
  align-items: flex-start;
  background-color: var(--white);
  border-radius: 10px;
  display: flex;
  height: 40px;
  justify-content: flex-end;
  min-width: 110px;
  padding: 13px 25px;
}

.frame-33476 .x26-8 {
  letter-spacing: 0;
  line-height: 10px;
  min-height: 12px;
  text-align: center;
  white-space: nowrap;
  width: 59px;
}

.frame-33476 .overlap-group-container {
  align-items: flex-start;
  display: flex;
  gap: 10px;
  margin-top: 10px;
  min-width: 350px;
  justify-content: center;
}

.frame-33476 .overlap-group22 {
  align-items: flex-start;
  background-color: var(--white);
  border-radius: 10px;
  display: flex;
  height: 40px;
  min-width: 110px;
  padding: 14px 25px;
}

.frame-33476 .x26-9 {
  letter-spacing: 0;
  line-height: 10px;
  text-align: center;
  white-space: nowrap;
  width: 60px;
}

.frame-33476 .overlap-group12 {
  align-items: flex-start;
  background-color: var(--white);
  border-radius: 10px;
  display: flex;
  height: 40px;
  justify-content: flex-end;
  min-width: 110px;
  padding: 14px 25px;
}

.frame-33476 .x26-10 {
  letter-spacing: 0;
  line-height: 10px;
  text-align: center;
  white-space: nowrap;
  width: 59px;
}

.frame-33476 .overlap-group21 {
  align-items: flex-start;
  background-color: #ff6c2e;
  border-radius: 10px;
  display: flex;
  height: 40px;
  justify-content: center;
  align-items: center;
  min-width: 109px;
  // padding: 10px 25px;
}

.frame-33476 .x26-11 {
  letter-spacing: 0;
  line-height: 10px;
  text-align: center;
  white-space: nowrap;
  width: 59px;
}

.frame-33476 .overlap-group14 {
  align-items: flex-end;
  background-color: var(--white);
  border-radius: 10px;
  display: flex;
  height: 40px;
  min-width: 110px;
  padding: 9px 26px;
}

.frame-33476 .x27 {
  letter-spacing: 0;
  line-height: normal;
  min-height: 21px;
  min-width: 58px;
  text-align: center;
}

.frame-33476 .x28 {
  letter-spacing: 0;
  line-height: normal;
  min-height: 21px;
  min-width: 60px;
  text-align: center;
}

.frame-33476 .x29 {
  letter-spacing: 0;
  line-height: normal;
  min-height: 21px;
  min-width: 59px;
  text-align: center;
}

.frame-33476 .frame-33443 {
  align-items: flex-start;
  display: flex;
  gap: 70px;
  margin-top: 10px;
  padding-bottom: 10px;
  min-width: 352px;
  justify-content: center;
}

.frame-33476 .botton-6 {
  background-color: var(--white);
  border-radius: 50px;
  height: 49px;
  overflow: hidden;
  padding: 11.5px 22.5px;
  // width: 110px;
}

.frame-33476 .suspend {
  color: var(--mountain-meadow);
  font-family: var(--font-family-poppins);
  font-size: var(--font-size-l);
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  min-height: 23px;
  min-width: 65px;
  text-align: center;
}

.frame-33476 .botton-7 {
  background-color: var(--button-green);
  border-radius: 50px;
  height: 49px;
  overflow: hidden;
  padding: 12px 37px;
  // width: 110px;
}

.frame-33476 .book {
  letter-spacing: 0;
  line-height: normal;
  min-height: 23px;
  min-width: 36px;
  text-align: center;
}

.frame-33476 .botton-8 {
  background-color: var(--cardinal);
  border-radius: 50px;
  height: 49px;
  overflow: hidden;
  padding: 11.5px 28.5px;
  // width: 110px;
}

.frame-33476 .cancel-4 {
  min-height: 23px;
  min-width: 53px;
}

.frame-33476 .menu-1 {
  height: 24px;
  width: 24px;
}

.frame-33476 .logout-4 {
  height: 24px;
  width: 24px;
}

.frame-33476 .tab_buttons-container-1 {
  margin-top: 24px;
}

.frame-33476 .tab_-buttons-2 {
  background-color: var(--concrete);
  opacity: 0.4;
  padding: 11px 67px;
}

.frame-33476 .today-2 {
  min-width: 36px;
  opacity: 0.5;
}

.frame-33476 .tab_-buttons-3 {
  background-color: var(--mountain-meadow);
  padding: 11px 32px;
  border-radius: 30px;
}

.frame-33476 .today-3 {
  min-width: 65px;
}

.frame-33476 .frame-33442-1 {
  align-items: flex-end;
  background-color: var(--mountain-meadow);
  display: flex;
  flex-direction: column;
  margin-right: 0.72px;
  margin-top: 8px;
  min-height: 147px;
  padding: 18.0px 6.9px;
  width: 389px;
}

.frame-33476 .mon-1 {
  letter-spacing: 0;
  line-height: normal;
  margin-top: -0.9px;
  min-height: 16px;
  min-width: 24px;
}

.frame-33476 .tue-1 {
  letter-spacing: 0;
  line-height: normal;
  margin-left: 35px;
  margin-top: -0.9px;
  min-height: 16px;
  min-width: 20px;
}

.frame-33476 .wed-1 {
  letter-spacing: 0;
  line-height: normal;
  margin-left: 34px;
  margin-top: -0.9px;
  min-height: 16px;
  min-width: 25px;
}

.frame-33476 .thu-1 {
  letter-spacing: 0;
  line-height: normal;
  margin-left: 33px;
  margin-top: -0.9px;
  min-height: 16px;
  min-width: 21px;
}

.frame-33476 .fri-1 {
  letter-spacing: 0;
  line-height: normal;
  margin-left: 34px;
  margin-top: -0.9px;
  min-height: 16px;
  min-width: 13px;
}

.frame-33476 .sat-1 {
  letter-spacing: 0;
  line-height: normal;
  margin-left: 35px;
  margin-top: -0.9px;
  min-height: 16px;
  min-width: 18px;
}

.frame-33476 .x5-2 {
  left: 50px;
  position: absolute;
  top: 10px;
}

.frame-33476 .x5-3 {
  min-height: 18px;
  min-width: 33px;
}

.frame-33476 .x6-1 {
  left: 28px;
}

.frame-33476 .overlap-group24 {
  // height: 468px;
  // margin-top: 19px;
  position: relative;
  width: 100%;
  justify-content: center;
  display: flex;
}

.frame-33476 .frame-33400 {
  align-items: flex-start;
  display: flex;
  // flex-direction: column;
  // gap: 8px;
  left: 0;
  height: 100%;
  /* position: absolute; */
  top: 0;
  width: 100%;
  margin-top: 10px;
  margin-bottom: 90px;
  flex-wrap: wrap;
  justify-content: center;
}

.frame-33476 .learner-card {
  /* align-items: flex-start; */
  /* background-color: var(--white); */
  /* border-radius: 25px; */
  display: flex;
  /* height: 106px; */
  // width: calc(100% - 20px);
  max-width: 330px;
  margin: 5px;
  /* padding: 11px 0px; */
  justify-content: center;
  height: 92px;
  flex-shrink: 0;
  border-radius: 25px;
  background: #FFF;
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.28);
  // margin: auto;
  padding: 10px;
}

.frame-33476 .ellipse-13 {
  height: 65px;
  margin-top: 1px;
  object-fit: cover;
  width: 65px;
  border-radius: 50%;
}

.frame-33476 .flex-col {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 4px;
  margin-left: 9px;
  min-height: 75px;
  width: 50%;
}

.frame-33476 .name-1 {
  margin-left: 1px;
  min-height: 21px;
}

.frame-33476 .group-33381 {
  align-items: flex-start;
  display: flex;
  gap: 5px;
  margin-left: 1px;
  min-width: 200px;
}

.frame-33476 .timjenningsexamplecom {
  letter-spacing: 0;
  line-height: normal;
  min-height: 15px;
  opacity: 0.5;
}

.frame-33476 .group-33380 {
  align-items: flex-start;
  display: flex;
  gap: 6px;
  // height: 30px;
  min-width: 200px;
}

.frame-33476 .address-1 {
  margin-top: -1px;
  min-height: 30px;
  opacity: 0.5;
}

.frame-33476 .flex-col-1 {
  align-items: flex-end;
  gap: 22px;
  /* margin-left: 19px; */
  min-height: 71px;
  width: 80px;
}

.frame-33476 .address-2 {
  min-height: 18px;
  min-width: 120px;
  text-align: right;
}

.frame-33476 .learner-card-2 {
  box-shadow: 0px 4px 4px #ff6d2e24;
}

.frame-33476 .flex-col-2 {
  align-items: flex-end;
  gap: 39px;
  margin-left: 19px;
  min-height: 75px;
  width: 70px;
}

.frame-33476 .botton-9 {
  background-color: var(--cardinal);
  border-radius: 50px;
  height: 56px;
  left: 0;
  overflow: hidden;
  padding: 11.5px 139px;
  position: absolute;
  top: 366px;
  // width: 350px;
}

.frame-33476 .no-show-8 {
  min-width: 71px;
}

.frame-33476 .learner_-profile {
  height: 1472px;
}

.frame-33476 .name-2 {
  margin-top: 53px;
  min-height: 27px;
  min-width: 122px;
  text-align: center;
}

.frame-33476 .group-33350 {
  align-items: flex-start;
  display: flex;
  gap: 5px;
  margin-left: 2.0px;
  margin-top: 5px;
  min-width: 246px;
}

.frame-33476 .aparna-apartments-be {
  min-height: 15px;
}

.frame-33476 .line-1 {
  height: 1px;
  margin-top: 16px;
  width: 350px;
}

.frame-33476 .group-33354-2 {
  align-items: center;
  display: flex;
  gap: 4px;
  min-width: 69px;
}

.frame-33476 .phone {
  letter-spacing: 0;
  line-height: normal;
  margin-bottom: 1.0px;
  min-height: 15px;
  min-width: 53px;
}

.frame-33476 .line-2 {
  height: 1px;
  margin-top: 8px;
  width: 350px;
}

.frame-33476 .distance {
  letter-spacing: 0;
  line-height: normal;
  min-height: 15px;
}

.frame-33476 .time {
  letter-spacing: 0;
  line-height: normal;
  min-height: 15px;
}

.frame-33476 .address-4 {
  margin-left: 2px;
  min-height: 15px;
  min-width: 41px;
  opacity: 0.5;
  text-align: center;
}

.frame-33476 .address-5 {
  margin-right: 2.0px;
  min-height: 15px;
  min-width: 58px;
  opacity: 0.5;
  text-align: center;
}

.frame-33476 .address-6 {
  margin-left: 2px;
  min-height: 15px;
  min-width: 61px;
  opacity: 0.5;
  text-align: center;
}

.frame-33476 .line-3 {
  height: 1px;
  margin-top: 12px;
  width: 350px;
}

.frame-33476 .frame-11-2 {
  align-items: flex-start;
  border-radius: 25px;
  gap: 69px;
  height: 181px;
  margin-top: 23px;
  min-width: 350px;
  padding: 16px;
  width: calc(100% - 30px);
  justify-content: center;
}

.frame-33476 .flex-col-3 {
  align-items: flex-start;
  margin-top: 1px;
  min-height: 136px;
  width: 45%;
}

.frame-33476 .frame-33395-1 {
  margin-top: 17px;
  min-width: 119px;
}

.frame-33476 .number-7 {
  margin-top: -1px;
  min-height: 21px;
}

.frame-33476 .frame-33396-1 {
  min-width: 164px;
}

.frame-33476 .number-8 {
  margin-top: -1px;
  min-height: 21px;
}

.frame-33476 .number-9 {
  margin-top: -1px;
  min-height: 21px;
}

.frame-33476 .group-container {
  align-self: flex-end;
  border-radius: 57.5px;
  height: 115px;
  position: relative;
  width: 115px;
}

.frame-33476 .frame-12-2 {
  align-items: flex-start;
  background-color: var(--papaya-whip);
  border-radius: 25px;
  display: flex;
  gap: 69px;
  height: 181px;
  margin-top: 10px;
  width: calc(100% - 30px);
  padding: 16px;
  justify-content: center;
}

.frame-33476 .flex-col-4 {
  align-items: flex-start;
  margin-top: 1px;
  min-height: 136px;
  width: 46%;
}

.frame-33476 .frame-33395 {
  align-items: flex-start;
  display: flex;
  gap: 8px;
  height: 21px;
  margin-top: 17px;
  min-width: 99px;
}

.frame-33476 .number-10 {
  margin-top: -1px;
  min-height: 21px;
}

.frame-33476 .frame-33396-2 {
  min-width: 108px;
}

.frame-33476 .number-11 {
  margin-top: -1px;
  min-height: 21px;
}

.frame-33476 .number-12 {
  margin-top: -1px;
  min-height: 21px;
}

.frame-33476 .frame-13-1 {
  align-items: flex-start;
  background-color: var(--cosmos);
  border-radius: 25px;
  display: flex;
  gap: 83px;
  height: 181px;
  margin-top: 10px;
  width: calc(100% - 30px);
  padding: 16px;
  justify-content: center;
}

.frame-33476 .flex-col-5 {
  align-items: flex-start;
  margin-top: 1px;
  min-height: 136px;
  width: 46%;
}

.frame-33476 .text-43 {
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  min-height: 21px;
}

.frame-33476 .frame-33396-3 {
  min-width: 105px;
}

.frame-33476 .text-44 {
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  min-height: 21px;
}

.frame-33476 .text-45 {
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  min-height: 21px;
}

.frame-33476 .number-13 {
  margin-top: -1px;
  min-height: 21px;
}

.frame-33476 .frame-33396 {
  align-items: flex-start;
  display: flex;
  gap: 8px;
  height: 21px;
  min-width: 92px;
}

.frame-33476 .number-14 {
  margin-top: -1px;
  min-height: 21px;
}

.frame-33476 .cancelled {
  letter-spacing: 0;
  line-height: normal;
  margin-left: 1.0px;
  min-height: 24px;
  min-width: 83px;
  text-align: center;
}

.frame-33476 .number-15 {
  margin-top: -1px;
  min-height: 21px;
}

.frame-33476 .number-16 {
  margin-top: -1px;
  min-height: 21px;
}

.frame-33476 .group-33380-1 {
  align-items: flex-start;
  display: flex;
  gap: 6px;
  height: 22px;
  margin-left: 9.0px;
  margin-top: 12px;
  min-width: 359px;
}

.frame-33476 .vuesaxboldlocation-1 {
  align-self: center;
  height: 16px;
  margin-top: 2.0px;
  width: 16px;
}

.frame-33476 .aparna-apartments-b {
  min-height: 22px;
  width: 335px;
}

.frame-33476 .overlap-group9 {
  // background-image: url(./assets/img/rectangle-5417-16@2x.png);
  background-position: 50% 50%;
  background-size: cover;
  height: 196px;
  margin-top: 11px;
  position: relative;
  width: calc(100% - 30px);
}

.frame-33476 .vuesaxboldlocation-2 {
  height: 23px;
  left: 192px;
  position: absolute;
  top: 86px;
  width: 22px;
}

.frame-33476 .flex-row-6 {
  align-items: center;
  align-self: flex-start;
  display: flex;
  gap: 96px;
  height: 36px;
  margin-left: 20px;
  margin-top: 12px;
  min-width: 230px;
}

.frame-33476 .my-rides {
  letter-spacing: 0;
  line-height: normal;
  // min-height: 36px;
  min-width: 110px;
  text-align: center;
}

.frame-33476 .tab_buttons-container-2 {
  // margin-top: 25px;
}

.frame-33476 .tab_-buttons-4 {
  background-color: var(--mountain-meadow);
  padding: 11px 42px;
}

.frame-33476 .today-4 {
  min-width: 86px;
}

.frame-33476 .tab_-buttons-5 {
  background-color: var(--concrete);
  padding: 11px 44px;
}

.frame-33476 .today-5 {
  min-width: 82px;
  opacity: 0.5;
}

.frame-33476 .group-33404-1 {
  align-items: flex-start;
  display: flex;
  // gap: 20px;
  // margin-left: 15px;
  // margin-top: 19px;
  // min-width: 397px;
  overflow-x: auto;
  width: 100%;
  border-bottom: 2px solid #E2E2E2;
}

.frame-33476 .x5-4 {
  left: 50px;
  position: absolute;
  top: 10px;
}

.frame-33476 .x5-5 {
  min-height: 18px;
  min-width: 33px;
}

.frame-33476 .x6-2 {
  left: 31px;
}

.frame-33476 .learner-card-3 {
  margin-top: 20px;
}

.frame-33476 .learner-card-1 {
  margin-top: 8px;
}

.frame-33476 .overlap-group28 {
  border-radius: 30px;
  // height: 844px;
  // position: relative;
  // width: 390px;
}

.frame-33476 .learner_-onbaording {
  // height: 844px;
  left: 0;
  position: absolute;
  top: 0;
}

.frame-33476 .flex-row-7 {
  align-items: center;
  align-self: center;
  display: flex;
  gap: 10%;
  height: 36px;
  margin-left: 20px;
  margin-top: 12px;
  min-width: 100%;
}

.frame-33476 .edit-profile-1 {
  letter-spacing: 0;
  line-height: normal;
  min-height: 36px;
  min-width: 130px;
  text-align: center;
}

.frame-33476 .overlap-group23 {
  height: 270px;
  margin-top: 25px;
  position: relative;
  width: 90%;
  border-radius: 25px;
  border: 1px solid var(--light-green, #1DC88C);
  background: var(--white, #FFF);
}

.frame-33476 .rectangle-5417 {
  background-color: var(--white);
  border-radius: 25px;
  height: 213px;
  left: 0;
  position: absolute;
  top: 69px;
  width: 350px;
}

.frame-33476 .line-1-1 {
  height: 1px;
  left: 18px;
  position: absolute;
  top: 224px;
  width: 315px;
}

.frame-33476 .group-33399 {
  align-items: center;
  background-color: #e8f6f1;
  border-radius: 50%;
  display: flex;
  height: 40%;
  left: 0px;
  width: 40%;
  padding: 0 9px;
  position: absolute;
  top: 8px;
  right: 0px;
  margin: auto;
}

.frame-33476 .png-item_2120423-1 {
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  width: 100%;
  border-radius: 50%;
}

.frame-33476 .normal-sedan-standard-car {
  /* left: 41px; */
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  /* top: 143px; */
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.frame-33476 .crn6848 {
  color: var(--mountain-meadow);
  font-family: var(--font-family-poppins);
  font-size: var(--font-size-xxl);
  font-weight: 600;
  // left: 132px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 146px;
  margin: auto;
  left: 0px;
  right: 0px;
}

.frame-33476 .botton-10 {
  background-color: var(--button-green);
  border-radius: 50px;
  height: 56px;
  margin-top: 343px;
  overflow: hidden;
  padding: 12px 153px;
  width: 350px;
}

.frame-33476 .group-33357 {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 2px;
  left: 44px;
  min-height: 32px;
  // position: absolute;
  top: 292px;
  // width: 99px;
}

.frame-33476 .start-date-1 {
  letter-spacing: 0;
  line-height: normal;
  // margin-right: 5.0px;
  margin-top: -1px;
  min-height: 15px;
  // min-width: 50px;
  text-align: center;
}

.frame-33476 .sunday-1-jan-2023 {
  letter-spacing: 0;
  line-height: normal;
  // margin-right: 4.0px;
  min-height: 15px;
  // min-width: 95px;
  opacity: 0.5;
  text-align: center;
}

.frame-33476 .group-33359 {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 2px;
  left: 171px;
  min-height: 32px;
  // position: absolute;
  top: 292px;
  // width: 106px;
}

.frame-33476 .end-date-1 {
  letter-spacing: 0;
  line-height: normal;
  // margin-right: 3.0px;
  margin-top: -1px;
  min-height: 15px;
  min-width: 45px;
  text-align: center;
}

.frame-33476 .tuesday-3-jan-2023 {
  letter-spacing: 0;
  line-height: normal;
  margin-right: 4.0px;
  min-height: 15px;
  min-width: 102px;
  opacity: 0.5;
  text-align: center;
}

.frame-33476 .group-33358 {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 2px;
  left: 305px;
  min-height: 32px;
  // position: absolute;
  top: 292px;
  width: 57px;
}

.frame-33476 .time-1 {
  letter-spacing: 0;
  line-height: normal;
  // margin-right: 4.0px;
  margin-top: -1px;
  min-height: 15px;
  // min-width: 25px;
  text-align: center;
}

.frame-33476 .x300-pm {
  letter-spacing: 0;
  line-height: normal;
  // margin-right: 4.0px;
  min-height: 15px;
  // min-width: 41px;
  opacity: 0.5;
  text-align: center;
}

.frame-33476 .group-33366-1 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  // gap: 8px;
  left: 20px;
  // min-height: 85px;
  position: absolute;
  top: 350px;
  width: 352px;
}

.frame-33476 .full-name {
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  min-height: 21px;
}

.frame-33476 .linh-nguyen-19 {
  min-height: 21px;
  min-width: 129px;
}

.frame-33476 .vuesaxboldframe {
  height: 24px;
  left: 25px;
  position: absolute;
  top: 17px;
  width: 24px;
}

.frame-33476 .group-33365 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 8px;
  left: 20px;
  min-height: 85px;
  position: absolute;
  top: 525px;
  width: 352px;
}

.frame-33476 .phone-number {
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  min-height: 21px;
}

.frame-33476 .overlap-group-20,
.frame-33365 .overlap-group-20 {
  align-items: center;
  background-color: var(--white);
  border-radius: 50px;
  display: flex;
  // height: 58px;
  margin-left: -0.75px;
  // min-width: 352px;
  // padding: 14.2px 24.2px;
}

.license-fee {
  @extend .group-33365;
  top: 410px !important;
  width: calc(100% - 30px) !important;
}

.frame-33476 .linh-nguyen-20 {
  // margin-left: 20px;
  margin-top: 1.0px;
  min-height: 21px;
  min-width: 105px;
}

.frame-33476 .group-33400 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 8px;
  left: 20px;
  min-height: 85px;
  position: absolute;
  top: 626px;
  width: 352px;
}

.frame-33476 .email {
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  min-height: 21px;
}

.frame-33476 .vuesaxboldsms-1 {
  height: 24px;
  left: 25px;
  position: absolute;
  top: 17px;
  width: 24px;
}

.frame-33476 .tag_01 {
  align-items: flex-start;
  background-color: var(--black);
  display: flex;
  height: 228px;
  padding: 23px 188px;
  width: 1327px;
}

.frame-33476 .learner-screens {
  letter-spacing: 0;
  line-height: normal;
  min-height: 180px;
  min-width: 951px;
  text-align: center;
}

.frame-33476 .address-7 {
  letter-spacing: 0;
  line-height: normal;
}

.frame-33476 .aparna-apartments {
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  opacity: 0.5;
}

.frame-33476 .botton-11 {
  align-items: flex-start;
  display: flex;
}

.frame-33476 .cancel-5 {
  letter-spacing: 0;
  line-height: normal;
  text-align: center;
}

.frame-33476 .car {
  letter-spacing: 0;
  line-height: normal;
}

.frame-33476 .component-key-5 {
  align-items: flex-start;
  display: flex;
  height: 48px;
  overflow: hidden;
  width: 122px;
}

.frame-33476 .edit {
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 20px;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
}

.frame-33476 .edit_-car_ {
  align-items: center;
  background-color: var(--white);
  // border-radius: 30px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  width: 390px;
}

.frame-33476 .ellipse-12-3 {
  align-self: center;
  border-radius: 8px;
  height: 10px;
  margin-top: 1.0px;
  width: 10px;
}

.frame-33476 .flex-col-6 {
  display: flex;
  flex-direction: column;
}

.frame-33476 .frame-11-3 {
  background-color: var(--aqua-squeeze);
  display: flex;
}

.frame-33476 .frame-33372-3 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 350px;
}

.frame-33476 .frame-33396-4 {
  align-items: flex-start;
  display: flex;
  gap: 8px;
  height: 21px;
  margin-top: 16px;
}

.frame-33476 .group-33367-3 {
  align-items: flex-start;
  display: flex;
}

.frame-33476 .group-33371-3 {
  align-items: flex-start;
  display: flex;
  // height: 40px;
  min-width: 320px;
}

.frame-33476 .group-33373-3 {
  align-items: flex-start;
  display: flex;
  // padding: 0px 20px;
}

.frame-33476 .learner-card-4 {
  align-items: flex-start;
  background-color: var(--white);
  border-radius: 25px;
  display: flex;
  height: 106px;
  min-width: 350px;
  padding: 15px 16px;
  justify-content: center;
}

.frame-33476 .learner_ {
  align-items: center;
  background-color: var(--white);
  // border-radius: 30px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  width: 100%;
}

.frame-33476 .linh-nguyen-21 {
  letter-spacing: 0;
  line-height: normal;
}

.frame-33476 .linh-nguyen-21-pass {
  letter-spacing: 0;
  line-height: normal;
  font-size: 20px;
  margin-bottom: -5px;
  margin-left: 5px;
  width: 26px;
}

.frame-33476 .name-3 {
  max-width: 150px;
  letter-spacing: 0;
  line-height: normal;
}

.frame-33476 .no-show-9 {
  letter-spacing: 0;
  line-height: normal;
  margin-left: 1px;
  min-height: 30px;
  text-align: center;
}

.frame-33476 .number-17 {
  letter-spacing: 0;
  line-height: normal;
}

.frame-33476 .overlap-group-container-3 {
  align-items: flex-start;
  display: flex;
  gap: 10px;
  min-width: 350px;
  justify-content: center;
  flex-wrap: wrap;
}

.frame-33476 .remaining-2 {
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  min-height: 21px;
  opacity: 0.5;
}

.frame-33476 .tab_-buttons-6 {
  align-items: flex-start;
  border-radius: 30px;
  display: flex;
  height: 48px;
  justify-content: center;
  min-width: 45%;
}

.frame-33476 .tab_buttons-container-3 {
  padding: 10px;
  align-items: flex-start;
  display: flex;
  margin-left: 5px;
  margin-top: 10px;
  overflow-x: auto;
  width: 100%;
}

.frame-33476 .today-6 {
  letter-spacing: 0;
  line-height: normal;
  min-height: 23px;
  text-align: center;
}

.frame-33476 .x5-6 {
  letter-spacing: 0;
  line-height: normal;
  text-align: center;
}

.frame-33476 .x6-3 {
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 10px;
}

// user css

/* screen - frame-33477 */

.frame-33477 {
  align-items: flex-start;
  border: 1px none;
  display: flex;
  justify-content: center;
  // height: 3489px;
  // min-width: 3448px;
}

.frame-33477 .search-result-screen-container {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 978px;
  // min-height: 2666px;
  width: 100%;
}

.frame-33477 .search-result-screen {
  align-items: flex-start;
  background-color: var(--white);
  // border-radius: 30px;
  display: flex;
  flex-direction: column;
  gap: 17px;
  height: 844px;
  overflow: hidden;
  width: 100%;
}

.frame-33477 .overlap-group12 {
  align-items: center;
  background-color: var(--mountain-meadow);
  display: flex;
  flex-direction: column;
  gap: 11px;
  min-height: 118px;
  width: 100%;
}

.frame-33477 .flex-row-2 {
  align-items: flex-end;
  display: flex;
  gap: 7px;
  margin-left: 1.0px;
  min-width: 345px;
  margin-top: 40px;
}

.frame-33477 .frame-33415 {
  align-items: center;
  background-color: var(--mountain-meadow);
  border-radius: 50px;
  display: flex;
  gap: 8px;
  height: 40px;
  min-width: 297px;
  padding: 0 7px;
}

.frame-33477 .vuesaxboldlocation-2 {
  height: 24px;
  width: 24px;
}

.frame-33477 .linh-nguyen {
  letter-spacing: 0;
  line-height: normal;
  margin-bottom: 2.0px;
  min-height: 21px;
  min-width: 163px;
  opacity: 0.5;
}

.frame-33477 .overlap-group2-1 {
  align-items: flex-end;
  // background-image: url(./assets/img/rectangle-5429-1@2x.png);
  background-color: var(--button-green);
  background-size: 100% 100%;
  display: flex;
  height: 41px;
  justify-content: flex-end;
  min-width: 41px;
  padding: 8px;
}

.frame-33477 .icon-user {
  height: 24px;
  width: 24px;
}

.frame-33477 .overlap-group {
  height: 709px;
  position: relative;
  width: 100%;
}

.frame-33477 .frame-33473 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 15px;
  left: 20px;
  min-height: 635px;
  position: absolute;
  top: 0;
  width: 100%;
}

.frame-33477 .group-334 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 9px;
  min-height: 119px;
  width: 100%;
}

.frame-33477 .flex-row {
  gap: 7px;
  height: 105px;
  min-width: 349px;
}

.frame-33477 .rectangle-5417 {
  height: 102px;
  width: 85px;
}

.frame-33477 .frame-33411 {
  align-items: flex-start;
  align-self: flex-end;
  display: flex;
  flex-direction: column;
  gap: 6px;
  min-height: 104px;
  width: calc(100% - 115px);
}

.frame-33477 .frame-33468 {
  align-items: center;
  display: flex;
  gap: 14px;
  height: 24px;
  min-width: 257px;
}

.frame-33477 .driving-school-name {
  align-self: flex-start;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  min-height: 24px;
  min-width: 167px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.frame-33477 .frame-33382 {
  align-items: flex-start;
  display: flex;
  gap: 5px;
  height: 15px;
  min-width: 43px;
}

.frame-33477 .vuesaxboldsms {
  align-self: center;
  height: 12px;
  margin-top: 0;
  width: 12px;
}

.frame-33477 .text-10 {
  line-height: normal;
  min-height: 15px;
  opacity: 0.5;
}

.frame-33477 .frame-33410 {
  align-items: flex-start;
  display: flex;
  gap: 42px;
  min-width: 257px;
}

.frame-33477 .frame-33380 {
  align-items: flex-start;
  display: flex;
  gap: 6px;
  height: 30px;
  min-width: 150px;
}

.frame-33477 .address {
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  max-height: 30px;
  opacity: 0.5;
  overflow: hidden;
}

.frame-33477 .frame-33381 {
  align-items: flex-start;
  display: flex;
  gap: 5px;
  height: 18px;
  min-width: 65px;
}

.frame-33477 .vuesaxboldsms-1 {
  align-self: center;
  // background-image: url(./assets/img/airline-stops-1@2x.png);
  background-size: 100% 100%;
  height: 16px;
  width: 16px;
}

.frame-33477 .x15-kms {
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  min-height: 18px;
  opacity: 0.5;
}

.frame-33477 .frame-33383 {
  align-items: flex-start;
  display: flex;
  gap: 5px;
  min-width: 90px;
}

.frame-33477 .vuesaxboldsms-5 {
  // background-image: url(./assets/img/toys-1@2x.png);
  background-size: 100% 100%;
  height: 15px;
  width: 15px;
}

.frame-33477 .xyz-abc-pqr {
  letter-spacing: 0;
  line-height: normal;
  margin-top: -0.95px;
  min-height: 15px;
  opacity: 0.5;
}

.frame-33477 .frame-33466 {
  align-items: flex-start;
  display: flex;
  height: 17px;
  min-width: 257px;
}

.frame-33477 .phone {
  letter-spacing: 0;
  line-height: 17px;
  margin-top: -1px;
  min-width: 66px;
  white-space: nowrap;
}

.frame-33477 .line {
  align-self: center;
  height: 12px;
  margin-left: 10px;
  width: 1px;
}

.frame-33477 .x100-120-kms {
  letter-spacing: 0;
  line-height: 17px;
  margin-left: 9px;
  margin-top: -1px;
  min-width: 81px;
  white-space: nowrap;
}

.frame-33477 .x25-30-days {
  letter-spacing: 0;
  line-height: 17px;
  margin-left: 9px;
  margin-top: -1px;
  white-space: nowrap;
}

.frame-33477 .line-1 {
  height: 1px;
  width: 350px;
}

.frame-33477 .text-11 {
  line-height: normal;
  min-height: 15px;
  opacity: 0.5;
}

.frame-33477 .vuesaxboldsms-2 {
  // background-image: url(./assets/img/toys-2@2x.png);
  background-size: 100% 100%;
  height: 15px;
  width: 15px;
}

.frame-33477 .text-12 {
  line-height: normal;
  min-height: 15px;
  opacity: 0.5;
}

.frame-33477 .vuesaxboldsms-3 {
  align-self: center;
  height: 12px;
  margin-bottom: 0;
  width: 12px;
}

.frame-33477 .text-13 {
  line-height: normal;
  min-height: 15px;
  opacity: 0.5;
}

.frame-33477 .vuesaxboldsms-4 {
  align-self: center;
  // background-image: url(./assets/img/toys-2@2x.png);
  background-size: 100% 100%;
  height: 15px;
  width: 15px;
}

.frame-33477 .text-14 {
  line-height: normal;
  min-height: 15px;
  opacity: 0.5;
}

.frame-33477 .bottom_-menu {
  align-items: flex-end;
  background-color: var(--white);
  border-radius: 30px 30px 0px 0px;
  box-shadow: 0px -3px 8px #9db2d621;
  display: flex;
  height: 90px;
  left: 0;
  width: 100%;
  padding: 0 22px;
  position: absolute;
  bottom: 0px;
  justify-content: center;
}

.frame-33477 .search {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 78px;
  padding: 4px 23px;
  width: 78px;
}

.frame-33477 .overlap-group-1 {
  align-items: flex-start;
  background-color: #0bb076;
  background-color: var(--mountain-meadow);
  border-radius: 50%;
  display: flex;
  height: 36px;
  min-width: 30px;
  padding: 6px 5.5px;
}

.frame-33477 .home {
  align-items: flex-start;
  background-image: url(./assets/img/group-1@2x.png);
  background-size: 100% 100%;
  display: flex;
  width: 24px;
}

.frame-33477 .home-1 {
  // background-image: url(./assets/img/search-1@2x.png);
}

.frame-33477 .search-1 {
  align-self: flex-end;
  letter-spacing: 0;
  line-height: normal;
  min-height: 13px;
  min-width: 31px;
  text-align: center;
}

.frame-33477 .line-1-1 {
  height: 1px;
  margin-top: 5px;
  width: 12px;
}

.frame-33477 .overlap-group-2 {
  height: 78px;
  margin-left: 16px;
  position: relative;
  width: 154px;
}

.frame-33477 .videos {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 3px;
  height: 78px;
  left: 40px;
  padding: 8px 23px;
  position: absolute;
  top: 0;
  width: 78px;
}

.frame-33477 ._black_24dp-1 {
  align-items: flex-start;
  align-self: center;
  background-image: url(./assets/img/group-1@2x.png);
  background-size: 100% 100%;
  display: flex;
  width: 24px;
}

.frame-33477 .play_circle {
  height: 24px;
  width: 24px;
}

.frame-33477 .videos-3 {
  letter-spacing: 0;
  line-height: normal;
  min-height: 13px;
  min-width: 31px;
  text-align: center;
}

.frame-33477 .rectangle-7 {
  background-color: var(--black);
  border-radius: 7px;
  height: 3px;
  left: 0;
  opacity: 0.6;
  position: absolute;
  top: 68px;
  width: 154px;
}

.frame-33477 .course {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 3px;
  height: 78px;
  margin-left: 20px;
  padding: 8px 22px;
  width: 78px;
}

.frame-33477 .course-1 {
  letter-spacing: 0;
  line-height: normal;
  min-height: 13px;
  min-width: 32px;
  text-align: center;
}

.frame-33477 .overlap-group21 {
  align-items: center;
  background: linear-gradient(180deg, rgb(242, 163, 29) 0%, rgb(206, 32, 41) 100%);
  display: flex;
  flex-direction: column;
  gap: 11px;
  min-height: 118px;
  width: 390px;
}

.frame-33477 .flex-row-3 {
  align-items: center;
  display: flex;
  gap: 6px;
  margin-left: 1.0px;
  min-width: 345px;
}

.frame-33477 .frame-33415-1 {
  align-items: center;
  background-color: #00000033;
  border-radius: 50px;
  display: flex;
  gap: 8px;
  height: 40px;
  min-width: 297px;
  padding: 0 7px;
}

.frame-33477 .vuesaxboldlocation-3 {
  height: 24px;
  width: 24px;
}

.frame-33477 .overlap-group2-2 {
  align-items: center;
  background-color: #b34e1c;
  border-radius: 10px;
  display: flex;
  height: 42px;
  min-width: 42px;
  padding: 0 9px;
}

.frame-33477 .icon-user-1 {
  height: 24px;
  width: 24px;
}

.frame-33477 .text-27 {
  line-height: normal;
  min-height: 15px;
  opacity: 0.5;
}

.frame-33477 .text-28 {
  line-height: normal;
  min-height: 15px;
  opacity: 0.5;
}

.frame-33477 .vuesaxboldsms-6 {
  align-self: center;
  // background-image: url(./assets/img/toys-1@2x.png);
  background-size: 100% 100%;
  height: 15px;
  width: 15px;
}

.frame-33477 .text-29 {
  line-height: normal;
  min-height: 15px;
  opacity: 0.5;
}

.frame-33477 .text-30 {
  line-height: normal;
  min-height: 15px;
  opacity: 0.5;
}

.frame-33477 .text-31 {
  line-height: normal;
  min-height: 15px;
  opacity: 0.5;
}

.frame-33477 .overlap-group-3 {
  align-items: flex-start;
  background: linear-gradient(180deg, rgb(242, 163, 29) 0%, rgb(206, 32, 41) 100%);
  border-radius: 15.5px;
  display: flex;
  height: 31px;
  min-width: 31px;
  padding: 3px 3.5px;
}

.frame-33477 .videos-4 {
  letter-spacing: 0;
  line-height: normal;
  min-height: 13px;
  min-width: 31px;
  text-align: center;
}

.frame-33477 .x-screen-container {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  // gap: 978px;
  // margin-left: 87px;
  // min-height: 2666px;
  width: 100%;
}

.frame-33477 .x2-driving-school-screen {
  align-items: flex-start;
  background-color: var(--white);
  // border-radius: 30px;
  display: flex;
  flex-direction: column;
  // height: 844px;
  overflow: hidden;
  width: 100%;
}

.frame-33477 .overlap-group-4 {
  align-items: flex-start;
  // background-image: url(./assets/img/rectangle-5428-3@2x.png);
  background-size: 100% 100%;
  display: flex;
  flex-direction: column;
  gap: 19px;
  min-height: 219px;
  width: 100%;
  // filter: brightness(70%);
}

.frame-33477 .logout {
  height: 24px;
  margin-left: 20px;
  width: 24px;
  margin-top: 20px;
}

.frame-33477 .frame-33419 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  margin-left: 20px;
  margin-top: 15px;
  min-height: 70px;
  width: 100%;
}

.container1 {
  width: 100%;
}

.frame-33477 .driving-school-name-1 {
  margin-top: -1px;
}

.frame-33477 .group-33364 {
  align-items: flex-start;
  display: flex;
  gap: 9px;
  // height: 15px;
  /* margin-top: 7px; */
  min-width: 116px;
  float: right;
  // margin-left: 10px;
}

.frame-33477 .group-33354 {
  height: 14px;
  width: 86px;
}

.frame-33477 .text-1 {
  line-height: 101.0px;
  min-height: 14px;
  min-width: 21px;
  text-align: center;
  white-space: nowrap;
}

.frame-33477 .group-33380 {
  align-items: flex-start;
  display: flex;
  gap: 6px;
  margin-top: 6px;
  min-width: 245px;
}

.frame-33477 .address-1 {
  letter-spacing: 0;
  line-height: normal;
  // margin-top: -1px;
  min-height: 15px;
  opacity: 0.5;
  width: 100%;
}

.frame-33477 .overlap-group-5 {
  // height: 528px;
  margin-top: 12px;
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 80px;
}

.frame-33477 .frame-33417 {
  align-items: flex-start;
  display: flex;
  /* flex-direction: column; */
  /* gap: 9px; */
  // left: 20px;
  /* width: 350px; */
  flex-wrap: wrap;
}

.frame-33477 .group-334-1 {
  align-items: flex-start;
  display: flex;
  gap: 15px;
  min-height: 104px;
  width: 350px;
  margin: 10px 10px;
  border-bottom: 1px solid #0000001a;
}

.frame-33477 .flex-row-1 {
  align-items: flex-end;
  display: flex;
  gap: 12px;
  margin-top: -3px;
  min-width: 350px;
}

.frame-33477 .rectangle-5417-1 {
  height: 86px;
  width: 115px;
}

.frame-33477 .overlap-group-6 {
  height: 89px;
  position: relative;
  width: 223px;
}

.frame-33477 .vuesaxboldarrow-circle-right-1 {
  height: 20px;
  left: 191px;
  position: absolute;
  top: 0;
  width: 20px;
}

.frame-33477 .frame-33474 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  left: 0;
  min-height: 89px;
  position: absolute;
  top: 0;
  width: 183px;
}

.frame-33477 .car-item-name {
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  min-height: 24px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 190px;
}

.frame-33477 .number-4 {
  letter-spacing: 0;
  line-height: normal;
  margin-top: 2px;
  min-height: 21px;
  opacity: 0.5;
}

.frame-33477 .frame-33467 {
  align-items: flex-start;
  justify-content: space-between;
  display: flex;
  /* gap: 16px; */
  /* height: 17px; */
  margin-top: 3px;
  min-width: 223px;
}

.frame-33477 .number {
  letter-spacing: 0;
  line-height: 17px;
  margin-top: -1px;
  min-width: 26px;
  white-space: nowrap;
}

.frame-33477 .line-2 {
  align-self: center;
  height: 12px;
  width: 1px;
}

.frame-33477 .address-2 {
  letter-spacing: 0;
  line-height: 17px;
  margin-top: -1px;
  min-width: 50px;
  white-space: nowrap;
}

.frame-33477 .address-3 {
  letter-spacing: 0;
  line-height: 17px;
  margin-top: -1px;
  white-space: nowrap;
}

.frame-33477 .number-5 {
  letter-spacing: 0;
  line-height: normal;
  margin-top: 2px;
  min-height: 21px;
  opacity: 0.5;
}

.frame-33477 .number-6 {
  letter-spacing: 0;
  line-height: normal;
  margin-top: 2px;
  min-height: 21px;
  opacity: 0.5;
}

.frame-33477 .number-7 {
  letter-spacing: 0;
  line-height: normal;
  margin-top: 2px;
  min-height: 21px;
  opacity: 0.5;
}

.frame-33477 .bottom_-menu-1 {
  left: 0;
  position: absolute;
  top: 438px;
}

.frame-33477 .search-2 {
  align-items: flex-end;
  display: flex;
  flex-direction: column;
  gap: 3px;
  height: 78px;
  padding: 8px 23px;
  width: 78px;
}

.frame-33477 .home-2 {
  align-self: center;
  // background-image: url(./assets/img/search-4@2x.png);
}

.frame-33477 .search-3 {
  letter-spacing: 0;
  line-height: normal;
  min-height: 13px;
  min-width: 31px;
  text-align: center;
}

.frame-33477 .videos-5 {
  letter-spacing: 0;
  line-height: normal;
  min-height: 13px;
  min-width: 31px;
  text-align: center;
}

.frame-33477 .frame-33417-1 {
  align-items: flex-start;
  display: flex;
  // flex-direction: column;
  gap: 15px;
  left: 20px;
  min-height: 461px;
  position: absolute;
  top: 0;
  width: 100%;
  justify-content: center;
  flex-wrap: wrap;

}

.frame-33477 .number-8 {
  letter-spacing: 0;
  line-height: normal;
  margin-top: 2px;
  min-height: 21px;
  opacity: 0.5;
}

.frame-33477 .number-9 {
  letter-spacing: 0;
  line-height: normal;
  margin-top: 2px;
  min-height: 21px;
  opacity: 0.5;
}

.frame-33477 .number-10 {
  letter-spacing: 0;
  line-height: normal;
  margin-top: 2px;
  min-height: 21px;
  opacity: 0.5;
}

.frame-33477 .number-11 {
  letter-spacing: 0;
  line-height: normal;
  margin-top: 2px;
  min-height: 21px;
  opacity: 0.5;
}

.frame-33477 .search-4 {
  letter-spacing: 0;
  line-height: normal;
  min-height: 13px;
  min-width: 31px;
  text-align: center;
}

.frame-33477 .videos-6 {
  letter-spacing: 0;
  line-height: normal;
  min-height: 13px;
  min-width: 31px;
  text-align: center;
}

.frame-33477 .x-selected-car-screen {
  align-items: center;
  background-color: var(--white);
  // border-radius: 30px;
  display: flex;
  flex-direction: column;
  height: 844px;
  overflow: hidden;
  width: 100%;
}

.frame-33477 .frame-33422 {
  align-items: flex-start;
  align-self: flex-start;
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-left: 20px;
  margin-top: 15px;
  min-height: 104px;
  width: 100%;
}

.frame-33477 .frame-33419-1 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  margin-left: 10px;
  margin-top: 10px;
  /* min-height: 70px; */
  width: 100%;
}

.frame-33477 .group-33364-1 {
  align-items: flex-start;
  display: flex;
  gap: 9px;
  height: 15px;
  min-width: 116px;
}

.frame-33477 .frame-33421 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 7px;
  min-height: 44px;
  width: 100%;
}

.frame-33477 .group-33380-1 {
  min-width: 100%;
}

.frame-33477 .frame-33420 {
  align-items: flex-start;
  display: flex;
  gap: 14px;
  height: 21px;
  min-width: 207px;
}

.frame-33477 .car-name-1 {
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  min-height: 21px;
  min-width: 73px;
}

.frame-33477 .frame-33403 {
  align-items: flex-start;
  align-self: center;
  display: flex;
  gap: 4px;
  height: 15px;
  min-width: 120px;
}

.frame-33477 .manufacture-year {
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  min-height: 15px;
  min-width: 92px;
  opacity: 0.5;
}

.frame-33477 .number-1 {
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  min-height: 15px;
}

.frame-33477 .frame-33407 {
  align-items: flex-start;
  display: flex;
  gap: 78px;
  height: 48px;
  margin-left: 1.0px;
  margin-top: 18px;
  min-width: 345px;
}

.frame-33477 .frame-33404 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 2px;
  min-height: 48px;
  width: 64px;
}

.frame-33477 .amount {
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  min-height: 23px;
  min-width: 64px;
  text-align: center;
}

.frame-33477 .price {
  letter-spacing: 0;
  line-height: normal;
  margin-left: 4.5px;
  min-height: 23px;
  min-width: 55px;
  text-align: center;
}

.frame-33477 .frame-33405 {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 2px;
  min-height: 48px;
  width: 70px;
}

.frame-33477 .distance {
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  min-height: 23px;
  min-width: 70px;
  text-align: center;
}

.frame-33477 .address-4 {
  letter-spacing: 0;
  line-height: normal;
  min-height: 23px;
  min-width: 54px;
  text-align: center;
}

.frame-33477 .frame-33406 {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 2px;
  min-height: 48px;
  width: 55px;
}

.frame-33477 .time {
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  min-height: 23px;
  min-width: 40px;
  text-align: center;
}

.frame-33477 .address-5 {
  letter-spacing: 0;
  line-height: normal;
  min-height: 23px;
  min-width: 55px;
  text-align: center;
}

.frame-33477 .date-month {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 5px;
  margin-left: 2.0px;
  margin-top: 5px;
  // min-height: 98px;
  width: 404px;
}

.frame-33477 .month {
  align-items: flex-start;
  display: flex;
  gap: 26px;
  min-width: 168px;
  justify-content: center;
}

.frame-33477 .iconly-light-arrow-left-2 {
  // background-image: url(./assets/img/iconly-light-arrow---left-2-1@2x.png);
  background-size: 100% 100%;
  height: 22px;
  width: 22px;
}

.frame-33477 .name {
  letter-spacing: 0;
  line-height: 21px;
  min-width: 72px;
  white-space: nowrap;
}

.frame-33477 .iconly-light-arrow-right-2 {
  // background-image: url(./assets/img/iconly-light-arrow---right-2-1@2x.png);
  background-size: 100% 100%;
  height: 22px;
  width: 22px;
}

.frame-33477 .date {
  align-items: flex-start;
  display: flex;
  gap: 2px;
  height: 61px;
  min-width: 404px;
  justify-content: center;
}

.frame-33477 .frame-3345 {
  align-items: center;
  background-color: var(--desert-storm);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  gap: 4px;
  min-height: 61px;
  padding: 8px 0;
  width: 40px;
}

.frame-33477 .tue {
  letter-spacing: 0;
  line-height: 21px;
  min-width: 21px;
  white-space: nowrap;
}

.frame-33477 .number-2 {
  letter-spacing: 0;
  line-height: 18px;
  min-width: 14px;
  white-space: nowrap;
}

.frame-33477 .wed {
  letter-spacing: 0;
  line-height: 21px;
  min-width: 26px;
  white-space: nowrap;
}

.frame-33477 .number-3 {
  letter-spacing: 0;
  line-height: 18px;
  min-width: 16px;
  white-space: nowrap;
}

.frame-33477 .thus {
  letter-spacing: 0;
  line-height: 21px;
  min-width: 29px;
  white-space: nowrap;
}

.frame-33477 .frame-33453 {
  align-items: center;
  background-color: var(--mountain-meadow);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  gap: 4px;
  min-height: 61px;
  padding: 8px 0;
  width: 50px;
}

.frame-33477 .fri {
  letter-spacing: 0;
  line-height: 21px;
  min-width: 15px;
  white-space: nowrap;
}

.frame-33477 .sat {
  letter-spacing: 0;
  line-height: 21px;
  min-width: 19px;
  white-space: nowrap;
}

.frame-33477 .place {
  letter-spacing: 0;
  line-height: 21px;
  min-width: 22px;
  white-space: nowrap;
}

.frame-33477 .mon {
  letter-spacing: 0;
  line-height: 21px;
  min-width: 25px;
  white-space: nowrap;
}

.frame-33477 .frame-33460 {
  align-items: flex-start;
  display: flex;
  gap: 43px;
  margin-top: 21px;
  min-width: 312px;
}

.frame-33477 .frame-334 {
  align-items: flex-start;
  display: flex;
  gap: 12px;
  height: 18px;
  min-width: 79px;
}

.frame-33477 .ellipse-21 {
  align-self: center;
  background-color: var(--mountain-meadow);
  border-radius: 5px;
  height: 10px;
  width: 10px;
}

.frame-33477 .available {
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  min-height: 18px;
  min-width: 57px;
  text-align: center;
}

.frame-33477 .frame-33459 {
  align-items: flex-start;
  display: flex;
  gap: 12px;
  height: 18px;
  min-width: 68px;
}

.frame-33477 .ellipse-21-1 {
  background-color: var(--cardinal);
}

.frame-33477 .booked {
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  min-height: 18px;
  min-width: 46px;
  text-align: center;
}

.frame-33477 .ellipse-21-2 {
  background-color: #ffb700;
}

.frame-33477 .changed {
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  min-height: 18px;
  min-width: 57px;
  text-align: center;
}

.frame-33477 .overlap-group-7 {
  // height: 240px;
  margin-top: 21px;
  position: relative;
  width: 372px;
  // overflow: scroll;
}

.frame-33477 .frame-33425 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 11px;
  left: 0;
  min-height: 201px;
  position: absolute;
  top: 0;
  width: 372px;
}

.frame-33477 .frame-3342 {
  align-items: flex-start;
  display: flex;
  gap: 2px;
  min-width: 372px;
  flex-wrap: wrap;
}

.frame-33477 .tab_-buttons {
  align-items: flex-start;
  background-color: var(--concrete);
  border-radius: 30px;
  display: flex;
  height: 42px;
  min-width: 90px;
  padding: 11px 21.5px;
}

.frame-33477 .x300-pm {
  letter-spacing: 0;
  line-height: normal;
  min-height: 18px;
  min-width: 47px;
  opacity: 0.5;
  text-align: center;
}

.frame-33477 .tab_-buttons-1 {
  align-items: flex-start;
  background-color: var(--concrete);
  border-radius: 30px;
  display: flex;
  height: 42px;
  min-width: 90px;
  padding: 11px 21px;
}

.frame-33477 .x300-pm-1 {
  min-width: 48px;
  opacity: 0.5;
}

.frame-33477 .tab_-buttons-12 {
  align-items: flex-start;
  background-color: #1dc78b80;
  border-radius: 30px;
  display: flex;
  height: 42px;
  min-width: 90px;
  padding: 11px 21px;
}

.frame-33477 .tab_-buttons-99 {
  align-items: flex-start;
  background-color: #fbe1bc;
  border: #e36f33 solid 1px;
  border-radius: 30px;
  display: flex;
  height: 42px;
  min-width: 90px;
  padding: 11px 21px;
}

.frame-33477 .x300-pm-6 {
  min-width: 48px;
}

.frame-33477 .x300-pm-2 {
  min-width: 47px;
}

.frame-33477 .tab_-buttons-2 {
  align-items: flex-start;
  background-color: var(--concrete);
  border-radius: 30px;
  display: flex;
  height: 42px;
  min-width: 90px;
  padding: 11px 22px;
}

.frame-33477 .x300-pm-3 {
  min-width: 46px;
  opacity: 0.5;
}

.frame-33477 .tab_-buttons-3 {
  align-items: flex-start;
  background-color: var(--concrete);
  border-radius: 30px;
  display: flex;
  height: 42px;
  min-width: 90px;
  padding: 11px 19px;
}

.frame-33477 .x300-pm-4 {
  min-width: 52px;
  opacity: 0.5;
}

.frame-33477 .tab_-buttons-4 {
  align-items: flex-start;
  background-color: var(--concrete);
  border-radius: 30px;
  display: flex;
  height: 42px;
  min-width: 90px;
  padding: 11px 19.5px;
}

.frame-33477 .x300-pm-5 {
  min-width: 51px;
  opacity: 0.5;
}

.frame-33477 .botton-1 {
  background-color: var(--mountain-meadow);
  left: 11px;
  // padding: 12px 151px;
  top: 184px;
  width: 350px;
}

.frame-33477 .no-show {
  letter-spacing: 0;
  line-height: normal;
  min-height: 30px;
  // min-width: 48px;
  text-align: center;
}

.frame-33477 .tue-1 {
  letter-spacing: 0;
  line-height: 21px;
  min-width: 21px;
  white-space: nowrap;
}

.frame-33477 .wed-1 {
  letter-spacing: 0;
  line-height: 21px;
  min-width: 26px;
  white-space: nowrap;
}

.frame-33477 .frame-33453-1 {
  align-items: center;
  background: linear-gradient(180deg, rgb(242, 163, 29) 0%, rgb(206, 32, 41) 100%);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  gap: 4px;
  min-height: 61px;
  padding: 8px 0;
  width: 50px;
}

.frame-33477 .fri-1 {
  letter-spacing: 0;
  line-height: 21px;
  min-width: 15px;
  white-space: nowrap;
}

.frame-33477 .sat-1 {
  letter-spacing: 0;
  line-height: 21px;
  min-width: 19px;
  white-space: nowrap;
}

.frame-33477 .mon-1 {
  letter-spacing: 0;
  line-height: 21px;
  min-width: 25px;
  white-space: nowrap;
}

.frame-33477 .available-1 {
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  min-height: 18px;
  min-width: 57px;
  text-align: center;
}

.frame-33477 .changed-1 {
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  min-height: 18px;
  min-width: 57px;
  text-align: center;
}

.frame-33477 .tab_-buttons-13 {
  height: 42px;
  width: 90px;
}

.frame-33477 .botton-2 {
  background: linear-gradient(180deg, rgb(242, 163, 29) 0%, rgb(206, 32, 41) 100%);
  left: 11px;
  padding: 12px 151px;
  top: 184px;
  width: 350px;
}

.frame-33477 .logout-1 {
  align-self: flex-start;
  margin-left: 20px;
  margin-top: 19px;
}

.frame-33477 .tab_buttons-container {
  align-items: flex-start;
  align-self: flex-end;
  display: flex;
  gap: 10px;
  margin-right: -86px;
  margin-top: 19px;
  min-width: 456px;
}

.frame-33477 .tab_-buttons-14 {
  align-items: flex-start;
  background-color: var(--mountain-meadow);
  border-radius: 30px;
  display: flex;
  height: 47px;
  min-width: 79px;
  padding: 11px 30px;
}

.frame-33477 .today {
  letter-spacing: 0;
  line-height: normal;
  min-height: 23px;
  min-width: 19px;
  text-align: center;
}

.frame-33477 .tab_-buttons-5 {
  align-items: flex-start;
  background-color: var(--concrete);
  border-radius: 30px;
  display: flex;
  height: 47px;
  min-width: 126px;
  padding: 11px 30px;
}

.frame-33477 .today-1 {
  min-width: 66px;
  opacity: 0.5;
}

.frame-33477 .tab_-buttons-6 {
  align-items: flex-start;
  background-color: var(--concrete);
  border-radius: 30px;
  display: flex;
  height: 47px;
  min-width: 92px;
  padding: 11px 30px;
}

.frame-33477 .new {
  letter-spacing: 0;
  line-height: normal;
  min-height: 23px;
  min-width: 32px;
  opacity: 0.5;
  text-align: center;
}

.frame-33477 .tab_-buttons-7 {
  align-items: flex-start;
  background-color: var(--concrete);
  border-radius: 30px;
  display: flex;
  height: 47px;
  min-width: 129px;
  padding: 11px 30px;
}

.frame-33477 .following {
  letter-spacing: 0;
  line-height: normal;
  min-height: 23px;
  min-width: 69px;
  opacity: 0.5;
  text-align: center;
}

.frame-33477 .group-container {
  align-items: flex-start;
  display: flex;
  gap: 14px;
  height: 188px;
  margin-left: 4.0px;
  margin-top: 14px;
  min-width: 354px;
}

.frame-33477 .group-3340 {
  /* align-items: flex-end; */
  /* display: flex; */
  /* flex-direction: column; */
  gap: 29px;
  min-height: 188px;
  width: 100%;
  /* height: 200px; */
  // border: 1px solid #c3c3c3;
  display: flex;
  flex-wrap: wrap;
  /* width: 150px; */
  margin-top: 20px;
  padding-left: 10px;
  padding-right: 10px;
}

.frame-33477 .overlap-group2 {
  // background-image: url(./assets/img/rectangle-3-1@2x.png);
  background-size: 100% 100%;
  height: 159px;
  position: relative;
  width: 46%;
}

.frame-33477 .rectangle-5418 {
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgb(0, 0, 0) 100%);
  border-radius: 0px 0px 25px 25px;
  height: 109px;
  left: 0;
  position: absolute;
  top: 50px;
  width: 170px;
}

.frame-33477 .overlap-group2 {

  // height: 50px;
  // left: 62px;
  // position: absolute;
  // top: 55px;
  // width: 50px;
  .iframe {
    border-radius: 22px;
    // margin: 10px;
    height: 100%;
  }
}

.frame-33477 .video-title-comes-here {
  letter-spacing: 0;
  line-height: normal;
  margin-right: 2px;
  min-height: 21px;
  min-width: 162px;
  text-align: center;
}

.frame-33477 .overlap-group5 {
  // background-image: url(./assets/img/rectangle-3-4@2x.png);
  background-size: 100% 100%;
  height: 159px;
  position: relative;
  width: 170px;
}

.frame-33477 .overlap-group3 {
  // background-image: url(./assets/img/rectangle-3-2@2x.png);
  background-size: 100% 100%;
  height: 159px;
  position: relative;
  width: 170px;
}

.frame-33477 .overlap-group6 {
  // background-image: url(./assets/img/rectangle-3-5@2x.png);
  background-size: 100% 100%;
  height: 159px;
  position: relative;
  width: 170px;
}

.frame-33477 .overlap-group1 {
  height: 272px;
  margin-top: 14px;
  position: relative;
  width: 390px;
}

.frame-33477 .bottom_-menu-2 {
  left: 0;
  position: absolute;
  top: 182px;
}

.frame-33477 .search-5 {
  letter-spacing: 0;
  line-height: normal;
  min-height: 13px;
  min-width: 31px;
  text-align: center;
}

.frame-33477 .videos-1 {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 78px;
  left: 40px;
  padding: 4px 23px;
  position: absolute;
  top: 0;
  width: 78px;
}

.frame-33477 .explore_black_24dp-1-1 {
  align-items: flex-end;
  background-image: url(./assets/img/group-1@2x.png);
  background-size: 100% 100%;
  display: flex;
  overflow: hidden;
  width: 24px;
}

.frame-33477 .explore_black_24dp-1 {
  align-items: flex-start;
  background-image: url(./assets/img/group-1@2x.png);
  background-size: 100% 100%;
  display: flex;
  margin-bottom: -1px;
  width: 24px;
}

.frame-33477 .icon-play {
  height: 24px;
  width: 24px;
}

.frame-33477 .videos-2 {
  align-self: flex-start;
  letter-spacing: 0;
  line-height: normal;
  min-height: 13px;
  min-width: 31px;
  text-align: center;
}

.frame-33477 .group-33410 {
  align-items: flex-end;
  display: flex;
  flex-direction: column;
  gap: 7px;
  left: 20px;
  min-height: 188px;
  position: absolute;
  top: 0;
  width: 170px;
}

.frame-33477 .overlap-group4 {
  // background-image: url(./assets/img/rectangle-3-3@2x.png);
  background-size: 100% 100%;
  height: 159px;
  position: relative;
  width: 170px;
}

.frame-33477 .group-33411 {
  align-items: flex-end;
  display: flex;
  flex-direction: column;
  gap: 7px;
  left: 204px;
  min-height: 188px;
  position: absolute;
  top: 0;
  width: 170px;
}

.frame-33477 .overlap-group7 {
  // background-image: url(./assets/img/rectangle-3-6@2x.png);
  background-size: 100% 100%;
  height: 159px;
  position: relative;
  width: 170px;
}

.frame-33477 .tab_-buttons-15 {
  align-items: flex-start;
  background: linear-gradient(180deg, rgb(242, 163, 29) 0%, rgb(206, 32, 41) 100%);
  border-radius: 30px;
  display: flex;
  height: 47px;
  min-width: 79px;
  padding: 11px 30px;
}

.frame-33477 .search-6 {
  letter-spacing: 0;
  line-height: normal;
  min-height: 13px;
  min-width: 31px;
  text-align: center;
}

.frame-33477 .course-screen-container {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 155px;
  margin-left: 87px;
  min-height: 3489px;
  width: 390px;
}



.frame-33477 .x5-course-screen {
  align-items: center;
  background-color: var(--white);
  // border-radius: 30px;
  display: flex;
  flex-direction: column;
  // height: 1667px;
  overflow: hidden;
  width: 100%;
  // margin-bottom: 50px;
}

.frame-33477 .overlap-group-8 {
  height: 155px;
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
}

.frame-33477 .logout-2 {
  left: 20px;
  position: absolute;
  top: 34px;
}

.frame-33477 .ellipse-11 {
  height: 132px;
  // left: 129px;
  object-fit: cover;
  position: absolute;
  top: 28px;
  width: 132px;
  border-radius: 50%;
  background-color: rgb(245, 227, 227);
}

.frame-33477 .frame-33428 {
  align-items: center;
  display: flex;
  flex-direction: column;
  // gap: 8px;
  margin-top: 10px;
  // min-height: 83px;
  width: 100%;
}

.frame-33477 .frame-33427 {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 5px;
  min-height: 47px;
  width: 100%;
}

.frame-33477 .name-1 {
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  min-height: 27px;
  min-width: 122px;
  text-align: center;
}

.frame-33477 .group-33350 {
  align-items: flex-start;
  display: flex;
  gap: 5px;
  margin-left: 2.0px;
  min-width: 246px;
  justify-content: center;
}

.frame-33477 .aparna-apartments-be {
  margin-top: -1px;
  min-height: 15px;
}

.frame-33477 .group-33412 {
  align-items: center;
  display: flex;
  flex-direction: column;
  // min-height: 266px;
  // width: calc(100% - 30px);
}

.frame-33477 .group-33354-1 {
  align-items: center;
  display: flex;
  gap: 4px;
  min-width: 69px;
}

.frame-33477 .car-hatchback-svgrepo-com-1 {
  height: 20px;
  width: 20px;
}

.frame-33477 .phone-1 {
  letter-spacing: 0;
  line-height: normal;
  margin-bottom: 1.0px;
  min-height: 15px;
  min-width: 53px;
}

.frame-33477 .line-2-1 {
  height: 1px;
  margin-top: 8px;
  width: 350px;
}

.frame-33477 .address-6 {
  letter-spacing: 0;
  line-height: normal;
  margin-left: 2px;
  min-height: 15px;
  min-width: 41px;
  opacity: 0.5;
  text-align: center;
}

.frame-33477 .address-7 {
  letter-spacing: 0;
  line-height: normal;
  margin-right: 2.0px;
  min-height: 15px;
  min-width: 58px;
  opacity: 0.5;
  text-align: center;
}

.frame-33477 .address-8 {
  letter-spacing: 0;
  line-height: normal;
  margin-left: 2px;
  min-height: 15px;
  min-width: 61px;
  opacity: 0.5;
  text-align: center;
}

.frame-33477 .group-33421 {
  align-items: flex-end;
  background-color: #f7f7f7;
  border-radius: 25px;
  display: flex;
  flex-direction: column;
  // gap: 21px;
  /* padding: 10px 18px; */
  margin-top: 10px;
  width: 100%;
  margin-left: 20px;
  margin: 10px auto;
  height: 100px;
  overflow-y: scroll;
  box-shadow: rgba(0, 0, 0, 0.17) 0px -23px 25px 0px inset, rgba(0, 0, 0, 0.15) 0px -36px 30px 0px inset, rgba(0, 0, 0, 0.1) 0px -79px 40px 0px inset, rgba(0, 0, 0, 0.06) 0px 2px 1px, rgba(0, 0, 0, 0.09) 0px 4px 2px, rgba(0, 0, 0, 0.09) 0px 8px 4px, rgba(0, 0, 0, 0.09) 0px 16px 8px, rgba(0, 0, 0, 0.09) 0px 32px 16px;
}

.frame-33477 .frame-33462 {
  align-items: center;
  display: flex;
  /* min-width: 100%; */
  justify-content: space-between;
  padding: 10px 7px;
  max-width: 330px;
  margin: auto;
}

.frame-33477 .today-2 {
  // margin-top: 1.0px;
  min-width: 49px;
}

.frame-33477 .frame-33461 {
  align-items: center;
  display: flex;
  gap: 43px;
  min-width: 156px;
}

.frame-33477 .active {
  letter-spacing: 0;
  line-height: normal;
  margin-top: 1.0px;
  min-height: 23px;
  min-width: 46px;
  text-align: center;
}

.frame-33477 .frame-6 {
  align-items: flex-start;
  background-color: var(--carnation);
  border-radius: 30px;
  display: flex;
  height: 26px;
  overflow: hidden;
  padding: 3.5px 12px;
  width: 67px;
}

.frame-33477 .cancelled {
  letter-spacing: 0;
  line-height: normal;
  min-height: 18px;
}

.frame-33477 .frame-33463 {
  align-items: center;
  display: flex;
  gap: 78px;
  min-width: 100%;
  justify-content: center;
}

.frame-33477 .tomorrow {
  letter-spacing: 0;
  line-height: normal;
  margin-top: 1.0px;
  min-height: 23px;
  min-width: 79px;
  text-align: center;
}

.frame-33477 .frame-33461-1 {
  align-items: center;
  display: flex;
  gap: 13px;
  min-width: 156px;
}

.frame-33477 .cancelled-1 {
  margin-top: 1.0px;
  min-height: 23px;
  min-width: 76px;
}

.frame-33477 .frame-33464 {
  align-items: center;
  display: flex;
  gap: 58px;
  min-width: 100%;
  justify-content: center;
}

.frame-33477 .tomorrow-1 {
  letter-spacing: 0;
  line-height: normal;
  margin-top: 1.0px;
  min-height: 23px;
  min-width: 99px;
  text-align: center;
}

.frame-33477 .group-33413 {
  align-items: center;
  display: flex;
  // flex-direction: column;
  // margin-right: 2px;
  // margin-top: 14px;
  width: 100%;
  padding-bottom: 10px;
  margin: auto;
  justify-content: center;
  // gap: 10px;
  flex-wrap: wrap;
}

.frame-33477 .frame-11 {
  align-items: flex-start;
  background-color: var(--aqua-squeeze-old);
  border-radius: 25px;
  display: flex;
  gap: 39px;
  /* height: 181px; */
  // min-width: 100%;
  padding: 16px;
  justify-content: center;
  max-width: 330px;
}

.frame-33477 .flex-col {
  flex-direction: column;
  margin-top: 1px;
  // min-height: 136px; 
  width: 164px;
}

.frame-33477 .frame-33395 {
  align-items: flex-start;
  display: flex;
  gap: 8px;
  height: 21px;
  // margin-top: 17px;
  min-width: 119px;
}

.frame-33477 .number-12 {
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  min-height: 21px;
}

.frame-33477 .frame-33396 {
  align-items: flex-start;
  display: flex;
  gap: 8px;
  height: 21px;
  // margin-top: 16px;
  min-width: 164px;
}

.frame-33477 .number-13 {
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  min-height: 21px;
}

.frame-33477 .number-14 {
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  min-height: 21px;
}

.frame-33477 .group-container-1 {
  align-self: flex-end;
  border-radius: 57.5px;
  height: 85px;
  position: relative;
  width: 85px;
}

.frame-33477 .frame-12 {
  align-items: flex-start;
  background-color: var(--papaya-whip);
  border-radius: 25px;
  display: flex;
  gap: 69px;
  // height: 181px;
  margin-top: 10px;
  min-width: 330px;
  padding: 16px;
  justify-content: center;
}

.frame-33477 .flex-col-1 {
  width: 134px;
}

.frame-33477 .frame-33395-1 {
  margin-top: 17px;
  min-width: 99px;
}

.frame-33477 .number-15 {
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  min-height: 21px;
}

.frame-33477 .frame-33396-1 {
  margin-top: 16px;
  min-width: 108px;
}

.frame-33477 .number-16 {
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  min-height: 21px;
}

.frame-33477 .number-17 {
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  min-height: 21px;
}

.frame-33477 .frame-13 {
  align-items: flex-start;
  background-color: var(--cosmos);
  border-radius: 25px;
  display: flex;
  gap: 13px;
  height: 181px;
  margin-top: 10px;
  min-width: 100%;
  padding: 16px;
  justify-content: center;
}

.frame-33477 .flex-col-2 {
  width: 120px;
}

.frame-33477 .text-3 {
  line-height: normal;
  min-height: 21px;
}

.frame-33477 .frame-33396-2 {
  margin-top: 16px;
  min-width: 105px;
}

.frame-33477 .text-4 {
  line-height: normal;
  min-height: 21px;
}

.frame-33477 .text-5 {
  line-height: normal;
  min-height: 21px;
}

.frame-33477 .number-18 {
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  min-height: 21px;
}

.frame-33477 .frame-33396-3 {
  min-width: 92px;
}

.frame-33477 .number-19 {
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  min-height: 21px;
}

.frame-33477 .cancelled-2 {
  margin-left: 1.0px;
  min-height: 24px;
  min-width: 83px;
}

.frame-33477 .number-20 {
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  min-height: 21px;
}

.frame-33477 .number-21 {
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  min-height: 21px;
}

.frame-33477 .group-33380-2 {
  height: 22px;
  margin-top: 4px;
  margin-bottom: 4px;
  min-width: 351px;
}

.frame-33477 .vuesaxboldlocation {
  align-self: center;
  height: 16px;
  // margin-top: 2.0px;
  width: 16px;
}

.frame-33477 .aparna-apartments-b {
  margin-top: -1.0px;
  min-height: 22px;
  width: 327px;
}

.frame-33477 .overlap-group1-1 {
  // background-image: url(./assets/img/rectangle-5417-16@2x.png);
  background-position: 50% 50%;
  background-size: cover;
  height: 196px;
  margin-top: 11px;
  position: relative;
  width: 100%;
}

.frame-33477 .vuesaxboldlocation-1 {
  height: 23px;
  left: 192px;
  position: absolute;
  top: 86px;
  width: 22px;
}

.frame-33477 .bottom_-menu-3 {
  margin-top: 30px;
}

.frame-33477 .search-7 {
  letter-spacing: 0;
  line-height: normal;
  min-height: 13px;
  min-width: 31px;
  text-align: center;
}

.frame-33477 .videos-7 {
  letter-spacing: 0;
  line-height: normal;
  min-height: 13px;
  min-width: 31px;
  text-align: center;
}

.frame-33477 .course-2 {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 78px;
  margin-left: 20px;
  padding: 4px 22px;
  width: 78px;
}

.frame-33477 .stream_black_24dp-1 {
  align-items: center;
  background-image: url(./assets/img/group-1@2x.png);
  background-size: 100% 100%;
  display: flex;
  height: 24px;
  padding: 0 1px;
  width: 24px;
}

.frame-33477 .stream_black_24dp-1-1 {
  align-items: flex-start;
  background-image: url(./assets/img/group-7@2x.png);
  background-size: 100% 100%;
  display: flex;
  width: 22px;
}

.frame-33477 .menu_book {
  height: 22px;
  width: 22px;
}

.frame-33477 .course-3 {
  align-self: flex-start;
  letter-spacing: 0;
  line-height: normal;
  min-height: 13px;
  min-width: 32px;
  text-align: center;
}

.frame-33477 .rectangle-5418-2 {
  background: linear-gradient(180deg, rgb(242, 163, 29) 0%, rgb(206, 32, 41) 100%);
}

.frame-33477 .aparna-apartments-be-1 {
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  min-height: 15px;
  opacity: 0.5;
}

.frame-33477 .group-33412-1 {
  align-items: center;
  display: flex;
  flex-direction: column;
  min-height: 95px;
  width: 350px;
}

.frame-33477 .end-date-1 {
  letter-spacing: 0;
  line-height: normal;
  min-height: 15px;
}

.frame-33477 .group-33421-1 {
  align-items: flex-end;
  background-color: #f7f7f7;
  border-radius: 25px;
  display: flex;
  flex-direction: column;
  gap: 21px;
  margin-top: 21px;
  min-height: 151px;
  padding: 15px 18px;
  width: calc(100% - 30px);
  justify-content: center;
}

.frame-33477 .group-33413-1 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  margin-right: 2.0px;
  margin-top: 13px;
  min-height: 985px;
  width: 350px;
}

.frame-33477 .number-22 {
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  min-height: 21px;
}

.frame-33477 .number-23 {
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  min-height: 21px;
}

.frame-33477 .number-24 {
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  min-height: 21px;
}

.frame-33477 .number-25 {
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  min-height: 21px;
}

.frame-33477 .driven-k-ms-1 {
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  min-height: 21px;
  min-width: 76px;
  opacity: 0.5;
}

.frame-33477 .number-26 {
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  min-height: 21px;
}

.frame-33477 .number-27 {
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  min-height: 21px;
}

.frame-33477 .text-18 {
  line-height: normal;
  min-height: 21px;
}

.frame-33477 .text-19 {
  line-height: normal;
  min-height: 21px;
}

.frame-33477 .text-20 {
  line-height: normal;
  min-height: 21px;
}

.frame-33477 .number-28 {
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  min-height: 21px;
}

.frame-33477 .remaining-2 {
  min-width: 76px;
}

.frame-33477 .number-29 {
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  min-height: 21px;
}

.frame-33477 .number-30 {
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  min-height: 21px;
}

.frame-33477 .remaining-3 {
  min-width: 76px;
}

.frame-33477 .number-31 {
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  min-height: 21px;
}

.frame-33477 .search-8 {
  letter-spacing: 0;
  line-height: normal;
  min-height: 13px;
  min-width: 31px;
  text-align: center;
}

.frame-33477 .videos-8 {
  letter-spacing: 0;
  line-height: normal;
  min-height: 13px;
  min-width: 31px;
  text-align: center;
}

.frame-33477 .overlap-group23 {
  border-radius: 30px;
  height: 844px;
  margin-left: 246px;
  margin-top: 1822px;
  position: relative;
  width: 390px;
  border-radius: 25px;
  border: 1px solid var(--light-green, #1DC88C);
  background: var(--white, #FFF);
}

.frame-33477 .popup-1 {
  align-items: flex-start;
  background-color: var(--white);
  border-radius: 30px;
  display: flex;
  height: 844px;
  left: 0;
  overflow: hidden;
  position: absolute;
  top: 0;
  width: 390px;
}

.frame-33477 .overlap-group-9 {
  height: 844px;
  position: relative;
  width: 476px;
}

.frame-33477 .bottom_-menu-4 {
  left: 0;
  position: absolute;
  top: 754px;
}

.frame-33477 .search-9 {
  letter-spacing: 0;
  line-height: normal;
  min-height: 13px;
  min-width: 31px;
  text-align: center;
}

.frame-33477 .tab_-buttons-8 {
  align-items: flex-start;
  background: linear-gradient(180deg, rgb(242, 163, 29) 0%, rgb(206, 32, 41) 100%);
  border-radius: 30px;
  display: flex;
  height: 47px;
  left: 20px;
  min-width: 79px;
  padding: 11px 30px;
  position: absolute;
  top: 107px;
}

.frame-33477 .tab_-buttons-9 {
  align-items: flex-start;
  background-color: var(--concrete);
  border-radius: 30px;
  display: flex;
  height: 47px;
  left: 109px;
  min-width: 126px;
  padding: 11px 30px;
  position: absolute;
  top: 107px;
}

.frame-33477 .tab_-buttons-10 {
  align-items: flex-start;
  background-color: var(--concrete);
  border-radius: 30px;
  display: flex;
  height: 47px;
  left: 245px;
  min-width: 92px;
  padding: 11px 30px;
  position: absolute;
  top: 107px;
}

.frame-33477 .tab_-buttons-11 {
  align-items: flex-start;
  background-color: var(--concrete);
  border-radius: 30px;
  display: flex;
  height: 47px;
  left: 347px;
  min-width: 129px;
  padding: 11px 30px;
  position: absolute;
  top: 107px;
}

.frame-33477 .group-33406 {
  align-items: flex-end;
  display: flex;
  flex-direction: column;
  gap: 7px;
  left: 20px;
  min-height: 188px;
  position: absolute;
  top: 168px;
  width: 170px;
}

.frame-33477 .group-33408 {
  align-items: flex-end;
  display: flex;
  flex-direction: column;
  gap: 7px;
  left: 20px;
  min-height: 188px;
  position: absolute;
  top: 370px;
  width: 170px;
}

.frame-33477 .group-33410-1 {
  align-items: flex-end;
  display: flex;
  flex-direction: column;
  gap: 7px;
  left: 20px;
  min-height: 188px;
  position: absolute;
  top: 572px;
  width: 170px;
}

.frame-33477 .group-33407 {
  align-items: flex-end;
  display: flex;
  flex-direction: column;
  gap: 7px;
  left: 204px;
  min-height: 188px;
  position: absolute;
  top: 168px;
  width: 170px;
}

.frame-33477 .group-33409 {
  align-items: flex-end;
  display: flex;
  flex-direction: column;
  gap: 7px;
  left: 204px;
  min-height: 188px;
  position: absolute;
  top: 370px;
  width: 170px;
}

.frame-33477 .group-33411-1 {
  align-items: flex-end;
  display: flex;
  flex-direction: column;
  gap: 7px;
  left: 204px;
  min-height: 188px;
  position: absolute;
  top: 572px;
  width: 170px;
}

.frame-33477 .rectangle-5430 {
  background-color: var(--black);
  height: 844px;
  left: 0;
  opacity: 0.8;
  position: absolute;
  top: 0;
  width: 390px;
}

.frame-33477 .rectangle-5431 {
  background-color: var(--white);
  border-radius: 30px;
  height: 274px;
  left: 58px;
  position: absolute;
  top: 278px;
  width: 274px;
}

.frame-33477 .botton {
  align-items: flex-start;
  background-color: var(--mountain-meadow);
  border-radius: 50px;
  display: flex;
  height: 56px;
  left: 69px;
  overflow: hidden;
  padding: 12px 98px;
  position: absolute;
  top: 484px;
  width: 252px;
}

.frame-33477 .btn-2 {
  letter-spacing: 0;
  line-height: normal;
  margin-left: 1px;
  min-height: 30px;
  min-width: 55px;
  text-align: center;
}

.frame-33477 .icon-close {
  height: 24px;
  left: 291px;
  position: absolute;
  top: 293px;
  width: 24px;
}

.frame-33477 .popup-1-1 {
  align-items: flex-start;
  background-color: var(--white);
  border-radius: 30px;
  display: flex;
  margin-left: 124px;
  margin-top: 1822px;
  overflow: hidden;
  width: 390px;
}

.frame-33477 .search-10 {
  letter-spacing: 0;
  line-height: normal;
  min-height: 13px;
  min-width: 31px;
  text-align: center;
}

.frame-33477 .icon-close-1 {
  height: 24px;
  left: 297px;
  position: absolute;
  top: 293px;
  width: 24px;
}

.frame-33477 .botton-3 {
  background-color: var(--black);
  left: 69px;
  padding: 12px 98px;
  top: 411px;
  width: 252px;
}

.frame-33477 .aparna-apartments {
  letter-spacing: 0;
  line-height: normal;
  opacity: 0.5;
}

.frame-33477 .bottom_-menu-5 {
  align-items: flex-end;
  background-color: var(--white);
  border-radius: 30px 30px 0px 0px;
  box-shadow: 0px -3px 8px #9db2d621;
  display: flex;
  height: 90px;
  min-width: 390px;
  padding: 0 22px;
}

.frame-33477 .botton-4 {
  align-items: flex-start;
  border-radius: 50px;
  display: flex;
  height: 56px;
  overflow: hidden;
  // margin-bottom: 110px;
  justify-content: center;
  align-items: center;
  width: 100%;
  // position: absolute;
}

.frame-33477 .cancelled-3 {
  letter-spacing: 0;
  line-height: normal;
  text-align: center;
}

.frame-33477 .distance-3 {
  letter-spacing: 0;
  line-height: normal;
  min-height: 15px;
}

.frame-33477 .driving-school-name-3 {
  letter-spacing: 0;
  line-height: normal;
  min-height: 27px;
  min-width: 191px;
  text-align: left;
  flex-wrap: wrap;
  display: flex;
}

.frame-33477 .ellipse-21-3 {
  align-self: center;
  border-radius: 5px;
  height: 10px;
  width: 10px;
}

.frame-33477 .flex {
  align-items: flex-start;
  display: flex;
}

.frame-33477 .flex-col-3 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  margin-top: 1px;
  // min-height: 136px;
}

.frame-33477 .frame-33396-4 {
  align-items: flex-start;
  display: flex;
  gap: 8px;
  height: 21px;
}

.frame-33477 .group-33380-3 {
  align-items: flex-start;
  display: flex;
  gap: 6px;
}

.frame-33477 .home-3 {
  background-size: 100% 100%;
  height: 24px;
  width: 24px;
}

.frame-33477 .icon-play-4 {
  height: 24px;
  width: 24px;
}

.frame-33477 .manufacture-year-9 {
  letter-spacing: 0;
  line-height: normal;
  margin-top: 2px;
  min-height: 21px;
  opacity: 0.5;
}

.frame-33477 .menu_book-9 {
  height: 24px;
  width: 24px;
}

.frame-33477 .new-4 {
  letter-spacing: 0;
  line-height: normal;
  min-height: 23px;
  min-width: 32px;
  opacity: 0.5;
  text-align: center;
}

.frame-33477 .play_circle-6 {
  height: 24px;
  width: 24px;
}

.frame-33477 .rectangle-5418-3 {
  height: 100px;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  background-color: var(--mountain-meadow);
}

.frame-33477 .remaining-4 {
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  min-height: 21px;
  opacity: 0.5;
}

.frame-33477 .text-2 {
  letter-spacing: 0;
  margin-top: -1px;
}

.frame-33477 .time-3 {
  letter-spacing: 0;
  line-height: normal;
  min-height: 15px;
}

.frame-33477 .today-3 {
  letter-spacing: 0;
  line-height: normal;
  // min-height: 23px;
  text-align: center;
}

.frame-33477 .x300-pm-7 {
  letter-spacing: 0;
  line-height: normal;
  min-height: 18px;
  text-align: center;
}


.alert-button-group {
  display: flex !important;
  flex-direction: column !important;

  .alert-button-inner.sc-ion-alert-md {
    justify-content: center;
  }
}

.poppins-bold-black-10px {
  @extend .poppins-bold-black-12px;
  font-size: var(--font-size-xs);
}

.alert-button-role-cancel,
.alert-button-role-confirm {
  background-color: black !important;
  color: white !important;
  margin: 10px !important;
  border-radius: 50px !important;
}

.alert-button-role-confirm {
  background-color: #0BB076 !important;
}


.rating {
  display: flex;
  justify-content: center;
  align-items: center;
  vertical-align: middle;

  ion-icon {
    color: #FFC107;
    font-size: 20px;
    margin-top: 2px;
    width: 16px !important;
    height: 16px !important;
  }

  .rating-number {
    font-size: 13px;
    padding-top: 4px;
    padding-left: 4px;
  }
}

ion-checkbox {
  --size: 32px;
  --background-checked: var(--mountain-meadow);
  height: 22px;
  width: 22px;
  min-width: 22px;
  margin-right: 10px;
}

ion-checkbox::part(container) {
  border-radius: 6px;
  border: 2px solid var(--mountain-meadow);
  ;
}

//  CUSTOM GLOBAL
ion-icon {
  color: var(--mountain-meadow);
}

.call {
  height: 16px;
  width: 16px;
  padding-left: 10px;
}

.danger {
  color: red;
}

//  common classes

.frame-33453,
.frame-3345,
.overlap-group-9,
.tab_-buttons,
.logout-2,
.menu,
.overlap-group19,
.overlap-group17,
.tab_-buttons-2,
.vuesaxboldarrow-circle-right,
.tab_-buttons-6,
.botton-7,
.botton-8,
.botton-10,
.logout,
.qr-code,
.botton-1,
.botton-4,
.vuesaxbold,
.learner-card,
.tab_-buttons-3,
.tab_-buttons-1,
.cancel,
.botton,
.vuesaxboldarrow-circle-down-1 {
  cursor: pointer;
}

.slot-disabled,
.disabled {
  cursor: not-allowed;
}

ion-select {
  padding: 5px 10px;
}

.relative {
  position: relative;
}

.width-80 {
  width: calc(100% - 20px);
}

.display-flex {
  display: flex;
}

.name-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 110px;
  text-align: left;
}

ion-input {
  input {
    padding-top: 5px !important;
    padding-bottom: 5px !important;
    // font-size: var(--font-size-s) !important;
    text-align: left !important;
    color: var(--black);
    font-family: var(--font-family-poppins);
    font-size: var(--font-size-m);
    font-style: normal;
    font-weight: 400;
  }
}

.frame-12-1,
.frame-11-1,
.overlap-group1-5 {
  ion-input {
    input {
      text-align: center !important;
    }
  }
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.car-name {
  align-items: flex-start;
  /* background-color: var(--mountain-meadow); */
  border-radius: 9px 9px 0px 0px;
  display: flex;
  height: 40px;
  justify-content: center;
  min-width: 90px;
  padding: 10px 0px;
  flex-direction: column;
  // justify-content: center;
  align-items: center;
  // box-shadow: 0px 30px 30px 0 rgba(0, 0, 0, 0.2), 0 3px 30px 0 rgba(0, 0, 0, 0.19);
  // border: 1px solid #E2E2E2;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
}

.slots-container {
  border-top: 2px solid #E2E2E2;
}

// common classes end

.car-number-caption {
  font-size: 8px;
  padding-top: 5px;
}

.padding-right-10 {
  padding-right: 10px;
}

// ::-webkit-scrollbar {
//   width: 12px;
//   background-color: #e7e7e7;
// }

// ::-webkit-scrollbar-thumb {
//   /* background-color: #787878; */
//   border-radius: 50px;
//   background: rgba(0, 0, 0, 0.5);
//   border: 1px solid #ccc;
// }

// "#19b37e", // Slightly darker
// "#169e71", // Moderately darker
// "#138964", // Significantly darker
// "#107557"  // Much darker

td,
th {
  // border: 1px solid #00000036;
  border-bottom: rgb(240, 242, 245) 0.8px solid;
  text-align: left;
  padding: 8px 4px;
  text-wrap: nowrap;
  font-size: 10px;
  // color: var(--mountain-meadow);
  color: #107557;
  @extend .poppins-medium-black-12px;
}

th {
  // background-color: #d9d1d1;
  font-weight: bold;
  text-transform: uppercase;
}

tr:nth-child(odd) {
  background-color: #f8fafc;
}

tr:nth-child(even) {
  background-color: white;
}

.wrap-text {
  text-wrap: wrap;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
  border: 1px solid #ddd;
}

.payment-table {
  margin-top: 10px;
  overflow-x: auto;
  width: calc(100% - 25px);
  // height: calc(100vh - 100px);
  // background-color: var(--papaya-whip);
  height: calc(100vh - 130px);
  overflow-y: auto;
  /* Enable vertical scrolling */
  border: 1px solid #ccc;
  /* Optional: Add a border for visibility */
  border-radius: 10px;
  /* Apply border-radius to the container */
  // box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Apply box-shadow to the container */
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, .1), 0 2px 4px -1px rgba(0, 0, 0, .06);
}

.inner-table {
  width: 100%;
  border-collapse: separate;
  /* Use separate instead of collapse */
  border-spacing: 0;
  /* Removes spacing between cells */
  border-radius: 10px;
  /* Adjust radius as needed */
  // overflow: hidden;
  // box-shadow: 0 4px 6px -1px rgba(0, 0, 0, .1), 0 2px 4px -1px rgba(0, 0, 0, .06);
  // margin-bottom: 20px;
  border-color: rgb(240, 242, 245)
    /* Ensures content stays within the rounded corners */
}

.small-font {
  font-size: 8px;
}

.sticky-header {
  position: sticky;
  top: 0px;
}

// media queries


/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .example {
    background: red;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .example {
    background: green;
  }

  .frame-33476 .group-33404,
  .frame-33476 .group-33404-1,
  .frame-33476 .tab_buttons-container-3 {
    justify-content: center;
  }

}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .example {
    background: blue;
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .frame-33477 .frame-33462 {
    min-width: 330px;
    max-width: 500px;
  }

  .frame-33477 .group-33421 {
    flex-wrap: wrap;
    height: 150px;
    margin: 12px;
    flex-direction: row;
  }


  /* Custom scrollbar for WebKit browsers */
  ::-webkit-scrollbar {
    width: 12px !important;
    /* Width of the entire scrollbar */
    height: 12px;
    /* Height of the horizontal scrollbar */
  }

  ::-webkit-scrollbar-track {
    background: #f1f1f1;
    /* Background of the scrollbar track */
    border-radius: 10px;
    /* Rounded corners for the track */
  }

  ::-webkit-scrollbar-thumb {
    background: #138964;
    /* Background of the scrollbar thumb */
    border-radius: 10px;
    /* Rounded corners for the thumb */
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #107557;
    /* Background of the scrollbar thumb on hover */
  }

  /* Custom scrollbar for Firefox */
  * {
    scrollbar-width: thin;
    /* Thin scrollbar */
    scrollbar-color: #888 #f1f1f1;
    /* Thumb color and track color */
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  .example {
    background: pink;
  }

  .split-pane-side {
    width: 100px !important;
  }
}

.car-locations {
  position: absolute;
  right: 50px;
}

.vertical-text {
  writing-mode: vertical-rl;
  transform: rotate(180deg);
}

.item-inner {
  border-style: initial !important;
}